import React, { useEffect, useState } from "react";
import { FaAngleUp } from "react-icons/fa6";
import { Link, useLocation, useParams } from "react-router-dom";

interface ISubMenu {
  index?: number;
  icon?: string;
  title: string;
  route: string;
  open?: boolean;
  subMenuItems?: ISubMenu[];
}
export const SubMenu: React.FC<ISubMenu> = ({
  index,
  icon,
  title,
  route,
  open,
  subMenuItems,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <li key={`submenu-${index}-${title}`}>
      {route?.length !== 0 ? (
        <Link to={route}>
          <button
            key={`menu-item-${title}-${index}`}
            className={`${location.pathname === route && "bg-green bg-light-white text-white"} group w-full flex rounded-md p-2 cursor-pointer hover:bg-light-white items-center gap-x-4 mt-2 
            ${index === 0 && "bg-light-white"}  hover:bg-green hover:text-white`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {icon && (
              <img
                src={icon}
                className={`${location.pathname === route && "brightness-200"} block h-6 w-6 duration-200 group-hover:brightness-200 group-hover:invert-1`}
              />
            )}
            <span
              className={`${!open && "hidden"} whitespace-nowrap origin-left duration-200`}
            >
              {title}
            </span>
            {subMenuItems && (
              <FaAngleUp
                className={`${!open && "hidden"} cursor-pointer ${!isMenuOpen && "rotate-180"}`}
              />
            )}
          </button>
        </Link>
      ) : (
        <button
          key={`menu-item-${title}-${index}`}
          className={`${location.pathname === route && "bg-green bg-light-white text-white"} group w-full flex rounded-md p-2 cursor-pointer hover:bg-light-white items-center gap-x-4 mt-2 
        ${index === 0 && "bg-light-white"}  hover:bg-green hover:text-white`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {icon && (
            <img
              src={icon}
              className={`${location.pathname === route && "brightness-200"} block h-6 w-6 duration-200 group-hover:brightness-200 group-hover:invert-1`}
            />
          )}
          <span
            className={`${!open && "hidden"} whitespace-nowrap origin-left duration-200`}
          >
            {title}
          </span>
          {subMenuItems && (
            <FaAngleUp
              className={`${!open && "hidden"} cursor-pointer ${!isMenuOpen && "rotate-180"}`}
            />
          )}
        </button>
      )}
      {subMenuItems && open && (
        <ul
          id={`dropdown-example-${index}-${title}`}
          className={`${!isMenuOpen && "hidden"} ml-4 py-2 space-y-2 duration-200`}
          key={`ul-sideMenu${index}-${title}`}
        >
          {subMenuItems?.map((subItemItem, subItemIndex) => (
            <li key={`submenu-item-${subItemItem.title}-${subItemIndex}`}>
              <Link to={subItemItem?.route}>
                <button
                  onClick={() => {}}
                  className={`${location.pathname === subItemItem?.route && "bg-green bg-light-white text-white"} group w-full flex rounded-md p-2 cursor-pointer hover:bg-light-white items-center gap-x-4 mt-2 
            ${index === 0 && "bg-light-white"}  hover:bg-green hover:text-white`}
                >
                  {subItemItem?.icon && (
                    <img
                      src={subItemItem?.icon}
                      className="block h-6 w-6 duration-200 group-hover:brightness-200 group-hover:invert-1"
                    />
                  )}
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {subItemItem?.title}
                  </span>
                </button>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default SubMenu;
