import { Field, Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import DocumentsService from "../../../common/api/documentsApi";
import { createDocument } from "../../../common/api/notificationApi";
import { GetReferenceService } from "../../../common/api/ordersApi";
import CalendarSelect from "../../../shared/Ui/Calendar/CalendarSelect";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import Stepper from "../../../shared/components/Stepper/Stepper";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import { generalDocumentInfo } from "../types";
import style from "./DocumentTransferAcceptance.module.css";
import { format } from "date-fns";
import {
  deleteDataDocument,
  saveDataDocument,
} from "../../../shared/slice/documentState";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "./../../../shared/Ui/SelectCustom/SelectCustomFormik";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import Loader from "../../../shared/Ui/Loader/Loader";
import { searchByIin } from "../../../common/api/authApi";
import { useTranslation } from "react-i18next";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { FaExclamationCircle } from "react-icons/fa";
import CalendarSelectFormik from "../../../shared/Ui/Calendar/ CalendarSelectFormik";

interface IInitialValues extends generalDocumentInfo {
  orgId: string; // +
  createUser: string; // +
  createdAt: string; // +
  updatedAt?: string;
  signingAt?: string;
  receiver: string; // +
  sender: string; // +
  status: string; // +

  refCountryExport: string;
  documentPaperNumber?: string; // Hoмер бум. документа
  documentPaperAt?: string; //Дата бум. документа
  document_id: string; // ИД документа
  customsClearanceRegNumber: string;
}

function DocumentTransferAcceptance() {
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  const dispatch = useAppDispatch();
  const documentState = useAppSelector((state: any) => state.documentState);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const navigate = useNavigate();
  const [releaseMethod, setReleaseMethod] = useState([]);
  const [operationType, setOperationType] = useState([]);
  const { transferAcceptanceDocumentId } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [receiverOrg, setReceiverOrg] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [statusType, setStatusType] = useState({
    type: "unsaved",
    label: t("documentStatus.new"),
  });
  const userData = useAppSelector((state) => state.auth.userData);

  const [organizationName, setOrganizationName] = useState({
    nameRu: "",
    nameKk: "",
  });
  const [initialValues, setInitialValues] = useState<any>({
    receiver: null,
    refOperationType: null,
    note: null, // Примечание
    hasPaperInfo: false,
    documentPaperNumber: null, // Hoмер бум. документа
    documentPaperAt: null, //Дата бум. документа

    id: null, // ИД
    docNumber: null, // Номер документа

    orgId: "",
    createUser: "",
    createdAt: "",
    // updatedAt: "",
    // signingAt: "",

    sender: "",
    status: "new",
  });
  console.log(initialValues,'initial');
  

  const formValidationSchema = Yup.object().shape({
    receiver: Yup.string()
      .matches(/^\d+$/, t("documentsError.only_numbers"))
      .test(
        "not available value",
        t("documentsError.sender_not_equal_receiver"),
        function (value) {
          return value !== userData.iinBin;
        },
      )
      ?.length(12, t("documentsError.minCharacters", { count: 12 }))
      .required(t("documentsError.required")),
    refOperationType: Yup.string().required(t("documentsError.required")),
    documentPaperNumber: Yup.string().required(t("documentsError.required")), // Hoмер бум. документа
    documentPaperAt: Yup.string().required(t("documentsError.required")),
    note: Yup.string()
      .nullable()
      .min(3, t("documentsError.minCharacters", { count: 3 }))
      .max(256, t("documentsError.maxCharacters", { count: 256 })),
  });

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: "documentStatus.error" };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      default:
        return t("documentType.unknown");
    }
  }

  useEffect(() => {
    const { type, label } = getStatusType(initialValues.status);
    setStatusType({ type, label });
  }, [initialValues]);

  async function onLoad() {
    GetReferenceService("/document/operation-type/list").then(
      (response: any) => {
        const options =
          response &&
          response.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setOperationType(options);
      },
    );

    if (!transferAcceptanceDocumentId && !documentState.isSafe)
      return Promise.resolve();

    if (transferAcceptanceDocumentId) {
      return DocumentsService.getDocument(transferAcceptanceDocumentId)
        .then((documentResponse) => {
          setInitialValues(documentResponse?.data);

          searchByIin(documentResponse?.data?.sender)
            .then((organizationNameResponse) => {
              setOrganizationName({
                nameRu: organizationNameResponse?.data[0]?.nameRu,
                nameKk: organizationNameResponse?.data[0]?.nameKk,
              });
            })
            .catch((error) => {
              console.error(
                "Ошибка при загрузке данных об отправителе:",
                error,
              );
            });
        })
        .then(() => {
          if (documentState.isSafe) {
            setIsFormChanged(true);
            setInitialValues((prev: any) => {
              return { ...prev, ...documentState.documentData };
            });
          }
        })
        .catch((error) => {
          console.error(
            "Ошибка при загрузке информации о документе о ввозе товаров из третьих стран:",
            error,
          );
          navigate("/404");
        });
    }

    if (!transferAcceptanceDocumentId && documentState.isSafe) {
      setIsFormChanged(true);
      setInitialValues((prev: any) => {
        return { ...prev, ...documentState.documentData };
      });
    }
  }

  const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      if (value?.length >= 3) {
        try {
          const result = await DocumentsService.getReceiverOrg(value);
          const options = result.data.map((org: any) => ({
            value: org.iinBin,
            label: `${org.iinBin} ${
              locale === "ru"
                ? org.nameRu.split(" ")[0]
                : locale === "kk"
                  ? org.nameKk.split(" ")[0]
                  : "-"
            } ${
              locale === "ru"
                ? org.nameRu.split(" ")[1]
                : locale === "kk"
                  ? org.nameKk.split(" ")[1]
                  : "-"
            }`,
          }));
          setReceiverOrg(options);
        } catch (error) {
          console.error("Error in debounced search:", error);
        }
      } else if (value?.length >= 1) {
        setReceiverOrg([]);
      }
    }, 300), // Задержка в 300 миллисекунд
    [],
  );

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue?.length < 3 && receiverOrg?.length === 0) {
      return t("document_transfer_acceptance.enter_iin_bin");
    } else {
      return t("document_transfer_acceptance.not_found");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const roleObj = () => {
      return {
        roleDoc: roleMatrix?.roleMatrix?.find((item) => item.id === 5)
          .specificAction.documents[1],
        roleDraft: roleMatrix?.roleMatrix?.find((item) => item.id === 6),
      };
    };

    setRole(roleObj);

    onLoad();
    setIsLoading(false);
  }, [transferAcceptanceDocumentId]);

  useEffect(() => {
    if (initialValues.receiver) {
      const options = DocumentsService.getReceiverOrg(
        initialValues.receiver,
      ).then((result) => {
        const options = result.data.map((org: any) => ({
          value: org.iinBin,
          label: `${org.iinBin} ${
            locale === "ru"
              ? org.nameRu.split(" ")[0]
              : locale === "kk"
                ? org.nameKk.split(" ")[0]
                : "-"
          } ${
            locale === "ru"
              ? org.nameRu.split(" ")[1]
              : locale === "kk"
                ? org.nameKk.split(" ")[1]
                : "-"
          }`,
        }));
        setReceiverOrg(options);
      });
    }
  }, [initialValues.receiver]);

  async function submitCreate(formikValues: any) {
    const backendData = {
      receiver: formikValues.receiver || null,
      refOperationType: formikValues.refOperationType || null,
      documentPaperNumber: formikValues.documentPaperNumber || null,
      documentPaperAt: formikValues.documentPaperAt || null,
      note: formikValues.note || null,
      hasPaperInfo: formikValues.hasPaperInfo || false,
      refDocType: 5,
      id: transferAcceptanceDocumentId || null,
    };

    if (transferAcceptanceDocumentId !== undefined) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          setIsFormChanged(false);
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    } else {
      const create = await createDocument(backendData)
        .then((response) => {
          setIsFormChanged(false);
          navigate(
            `/documents/transfer-acceptance/${response.data.document.id}`,
          );
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormChanged !== false &&
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("transfer-acceptance"),
  );

  if (isLoading && !role) return <Loader />;

  if (!role || !role?.roleDraft?.view) {
    return <PageNotRole />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formikValues) => submitCreate(formikValues)}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={formValidationSchema}
    >
      {(formik) => (
        <Form className="max-w-[1585px] w-full m-t-[20px]">
          {blocker && blocker.state === "blocked" ? (
            <ModalComponent
              isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
              onClose={() => {
                if (blocker && typeof blocker.reset === "function") {
                  blocker.reset();
                } else {
                  console.error("Reset function is not available.");
                }
              }}
              headerContent={t("documentNotification.notification")}
              content={
                <p>{t("documentNotification.unsaved_changes_warning")}</p>
              }
              customContent={
                <>
                  <ModalButton
                    type="button"
                    color="green"
                    onClick={() => {
                      if (blocker && typeof blocker.proceed === "function") {
                        blocker.proceed();
                      } else {
                        console.error("Proceed function is not available.");
                      }
                    }}
                  >
                    {t("mainButtons.continue")}
                  </ModalButton>
                  <ModalButton
                    type="button"
                    onClick={() => {
                      if (blocker && typeof blocker.reset === "function") {
                        blocker.reset();
                      } else {
                        console.error("Reset function is not available.");
                      }
                    }}
                  >
                    {t("mainButtons.cancel")}
                  </ModalButton>
                </>
              }
            />
          ) : null}
          <div className="flex flex-col gap-[10px]">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <div>
                {transferAcceptanceDocumentId ? (
                  <div className="flex gap-[20px]">
                    <div className="flex flex-col">
                      <StatusBadge type={statusType.type}>
                        {t("documentLabels.status") + ": " + statusType.label}
                      </StatusBadge>
                      {initialValues?.createdAt && (
                        <div className="text-[14px] font-semibold text-gray-700">
                          {format(
                            new Date(initialValues?.createdAt),
                            "dd.MM.yyyy, HH:mm",
                          )}
                        </div>
                      )}
                    </div>
                    <span className="flex gap-[10px]">
                      <div>{getDescription(formik.values.refDocType)}:</div>
                      <div>
                        {formik.values.docNumber
                          ? formik.values.docNumber
                          : " " + t("mainLabels.not_generated")}
                      </div>
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-[20px]">
                    <StatusBadge type={statusType.type}>
                      {t("documentLabels.status") + ": " + statusType.label}
                    </StatusBadge>
                    <div>
                      {t("documentLabels.create_document")}
                      {" :"}
                      {t("documentType.goods_transfer_act")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-between ">
              <div className="my-auto">
                <Stepper />
              </div>

              <div className="flex flex-row gap-[20px] flex-wrap">
                {!isLoading && (
                  <>
                    <button
                      className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        dispatch(deleteDataDocument());
                        if (initialValues.status == "DRAFT") {
                          console.log("Navigating to drafts");
                          navigate("/documents/drafts");
                        } else if (userData.iinBin !== initialValues.receiver) {
                          console.log("Navigating to sent");
                          navigate("/documents/sent");
                        } else {
                          console.log("Navigating to inbox");
                          navigate("/documents/inbox");
                        }
                      }}
                    >
                      {t("mainButtons.close")}
                    </button>

                    {((formik.values.status === "new" && role.roleDraft.edit) ||
                      (formik.values.status == "DRAFT" &&
                        role.roleDraft.edit)) && (
                      <div className="flex flex-row gap-[20px] flex-wrap">
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                          type="submit"
                        >
                          {t("documentLabels.check_completion")}
                        </button>
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                          type="button"
                          onClick={() => {
                            dispatch(deleteDataDocument());
                            submitCreate(formik.values);
                          }}
                        >
                          {t("mainButtons.save")}
                        </button>
                      </div>
                    )}
                    <button
                      className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        if (isFormChanged) {
                          dispatch(saveDataDocument(formik.values));
                        }
                        navigate("orders");
                      }}
                    >
                      {t("mainButtons.next")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px] pt-[20px]">
              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <span className={style.labelSpan}>
                      {t("documentLabels.iinBin") +
                        ", " +
                        t("documentLabels_2.organization_name_sender")}
                    </span>
                  </div>

                  <span className={style.spanValue}>
                    {transferAcceptanceDocumentId
                      ? formik?.values?.sender
                      : userData?.iinBin || "-"}
                    ,{" "}
                    {transferAcceptanceDocumentId
                      ? locale === "ru"
                        ? organizationName?.nameRu
                        : locale === "kk"
                          ? organizationName?.nameKk
                          : "-"
                      : userData?.organizationName || "-"}
                  </span>
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("document_transfer_acceptance.receiver_label")} *
                    </label>
                    {/* {formik.touched.receiver && formik.errors.receiver && (
                      <div className={style.errorLabel}>
                        {typeof formik.errors.receiver === "string"
                          ? formik.errors.receiver
                          : t("document_transfer_acceptance.receiver_error")}
                      </div>
                    )} */}
                    {formik.touched.receiver && formik.errors.receiver && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.receiver === "string"
                            ? formik.errors.receiver
                            : t("document_transfer_acceptance.receiver_error")}
                        </div>
                      </div>
                    )}
                  </div>
                  <Field
                    type="number"
                    value={formik.values.receiver}
                    component={SelectCustomFormik}
                    name="receiver"
                    placeholder={t("documentLabels.choose_option")}
                    size="sm"
                    isMulti={false}
                    options={receiverOrg}
                    onInputChange={debouncedSearch}
                    isDisabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(selectedOption: any) => {
                      const newValue = selectedOption
                        ? selectedOption.value
                        : "";
                      setIsFormChanged(true);
                    }}
                    noOptionsMessage={noOptionsMessage}
                  />
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("document_transfer_acceptance.operation_type_label")} *
                    </label>
                    {/* {formik.touched.refOperationType &&
                      formik.errors.refOperationType && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.refOperationType === "string"
                            ? formik.errors.refOperationType
                            : t(
                                "document_transfer_acceptance.operation_type_error",
                              )}
                        </div>
                      )} */}
                    {formik.touched.refOperationType &&
                      formik.errors.refOperationType && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.refOperationType === "string"
                              ? formik.errors.refOperationType
                              : t(
                                  "document_transfer_acceptance.operation_type_error",
                                )}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    value={formik.values.refOperationType}
                    component={SelectCustomFormik}
                    name="refOperationType"
                    placeholder={t("documentLabels.choose")}
                    size="sm"
                    isMulti={false}
                    options={operationType}
                    isDisabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(selectedOption: any) => {
                      const newValue = selectedOption
                        ? selectedOption.value
                        : "";
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap max-w-[950px]">
                <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("document_transfer_acceptance.note_label")}
                    </label>
                    {/* {formik.touched.note && formik.errors.note && (
                      <div className={style.errorLabel}>
                        {typeof formik.errors.note === "string"
                          ? formik.errors.note
                          : t("document_transfer_acceptance.note_error")}
                      </div>
                    )} */}
                    {formik.touched.note && formik.errors.note && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.note === "string"
                            ? formik.errors.note
                            : t("document_transfer_acceptance.note_error")}
                        </div>
                      </div>
                    )}
                  </div>
                  <Field
                    as="textarea"
                    name="note"
                    placeholder={t("documentLabels.choose_option")}
                    className={`${style.textareaPlaceholder} textarea-placeholder w-full h-[45px] min-h-[45px] border border-[#a3c0d2] rounded-lg px-4 pt-3 text-sm text-[#2e3138] font-medium leading-5 outline-none`}
                    value={formik.values.note}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex gap-[20px]">
                <CheckBox
                  id="myCheckbox"
                  checked={formik.values.hasPaperInfo}
                  onChange={() =>
                    formik.setFieldValue(
                      "hasPaperInfo",
                      !formik.values.hasPaperInfo,
                    )
                  }
                />
                <span className="font-semibold text-[17px]">
                  {t("document_transfer_acceptance.paper_info")}
                </span>
              </div>
              {formik.values.hasPaperInfo && (
                <div className="flex gap-[20px] flex-wrap max-w-[950px]">
                  <div className={style.inputField1}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t(
                          "document_transfer_acceptance.document_number_label",
                        )}{" "}
                        *
                      </label>
                      {/* {formik.touched.documentPaperNumber &&
                        formik.errors.documentPaperNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.documentPaperNumber ===
                            "string"
                              ? formik.errors.documentPaperNumber
                              : t(
                                  "document_transfer_acceptance.document_number_error",
                                )}
                          </div>
                        )} */}
                      {formik.touched.documentPaperNumber &&
                        formik.errors.documentPaperNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.documentPaperNumber ===
                              "string"
                                ? formik.errors.documentPaperNumber
                                : t(
                                    "document_transfer_acceptance.document_number_error",
                                  )}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="documentPaperNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.documentPaperNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.inputField}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t("document_transfer_acceptance.date_label")} *
                      </label>
                      {/* {formik.touched.documentPaperAt &&
                        formik.errors.documentPaperAt && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.documentPaperAt === "string"
                              ? formik.errors.documentPaperAt
                              : t("document_transfer_acceptance.date_error")}
                          </div>
                        )} */}
                      {formik.touched.documentPaperAt &&
                        formik.errors.documentPaperAt && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.documentPaperAt === "string"
                                ? formik.errors.documentPaperAt
                                : t("document_transfer_acceptance.date_error")}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                      name="documentPaperAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      documentStatus ={initialValues.status}
                      onChange={() => setIsFormChanged(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {isLoading && <Loader />}
          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
        </Form>
      )}
    </Formik>
  );
}

export default DocumentTransferAcceptance;
