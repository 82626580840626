import React from "react";
import { useField, FieldProps } from "formik";
import { SelectCustom } from "./SelectCustom";

interface OptionType {
  label: string;
  value: any;
}

type ValueType = OptionType | OptionType[];

interface SelectFieldProps extends FieldProps {
  options: OptionType[];
  isMulti?: boolean;
  isSearchEnabled?: boolean;
  onChange?: any;
  onInputChange?: any;
  noOptionsMessage?: any;
}

function SelectCustomFormik({
  field,
  form,
  options,
  isMulti,
  isSearchEnabled = true,
  onChange,
  onInputChange,
  noOptionsMessage,
  ...props
}: SelectFieldProps) {
  const handleChange = (option: ValueType) => {
    const valueToSet = isMulti
      ? (option as OptionType[])?.map((item: OptionType) => item?.value)
      : (option as OptionType)?.value;

    form.setFieldValue(field.name, valueToSet);

    if (onChange) {
      onChange(valueToSet);
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options?.filter((option: any) => field.value?.includes(option.value))
        : field.value !== null
          ? options?.find((option: any) => option.value === field.value)
          : field.value;
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  return (
    <SelectCustom
      {...field}
      {...props}
      isClearable={true}
      isMulti={isMulti}
      isSearchable={!isMulti}
      onChange={handleChange}
      onBlur={field.onBlur}
      value={getValue()}
      options={options}
      onInputChange={onInputChange}
      noOptionsMessage={noOptionsMessage}
    />
  );
}

export default SelectCustomFormik;
