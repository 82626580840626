import { createSlice } from "@reduxjs/toolkit";

export const userSettingsSlice = createSlice({
  name: "userSettingsSlice",
  initialState: {
    pageSize: 10,
  },
  reducers: {
    editPageSize: (state, action) => {
      state = {
        ...state,
        pageSize: action.payload,
      };
    },
  },
});

export const { editPageSize } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
