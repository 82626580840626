import { createSlice } from "@reduxjs/toolkit";

export const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState: {
    invoice: {
      forCompliance: "",
      countryOrigin: 130,
      assayOffice: "",
      releaseMethod: "",
      currencyType: "",
      documents: [],
      orgId: null,
      applicantId: null,
      id: null,
      createdAt: null,
      signingAt: null,
      status: null,
      updatedAt: null,
    },
  },
  reducers: {
    editStepAll: (state, action) => {
      state.invoice = {
        ...action.payload,
      };
    },

    deleteStepAll: (state) => {
      state.invoice = {
        forCompliance: "",
        countryOrigin: 130,
        assayOffice: "",
        releaseMethod: "",
        currencyType: "",
        documents: [],
        orgId: null,
        applicantId: null,
        id: null,
        createdAt: null,
        signingAt: null,
        status: null,
        updatedAt: null,
      };
    },

    editForCompliance: (state, action) => {
      state.invoice = {
        ...state.invoice,
        forCompliance: action.payload,
      };
    },

    editCountryOrigin: (state, action) => {
      state.invoice = {
        ...state.invoice,
        countryOrigin: action.payload,
      };
    },

    editAssayOffice: (state, action) => {
      state.invoice = {
        ...state.invoice,
        assayOffice: action.payload,
      };
    },

    editReleaseMethod: (state, action) => {
      state.invoice = {
        ...state.invoice,
        releaseMethod: action.payload,
      };
    },

    editCurrencyType: (state, action) => {
      state.invoice = {
        ...state.invoice,
        currencyType: action.payload,
      };
    },

    editDocuments: (state, action) => {
      state.invoice = {
        ...state.invoice,
        documents: action.payload,
      };
    },
  },
});

export const {
  editStepAll,
  deleteStepAll,
  editForCompliance,
  editCountryOrigin,
  editAssayOffice,
  editReleaseMethod,
  editCurrencyType,
  editDocuments,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
