export const bicData = [
  {
    value: "ATYNKZKA",
    label: "ATYNKZKA",
  },
  {
    value: "BKCHKZKA",
    label: "BKCHKZKA",
  },
  {
    value: "BRKEKZKA",
    label: "BRKEKZKA",
  },
  {
    value: "CASPKZKA",
    label: "CASPKZKA",
  },
  {
    value: "CEDUKZKA",
    label: "CEDUKZKA",
  },
  {
    value: "CITIKZKA",
    label: "CITIKZKA",
  },
  {
    value: "DVKAKZKA",
    label: "DVKAKZKA",
  },
  {
    value: "EABRKZKA",
    label: "EABRKZKA",
  },
  {
    value: "EURIKZKA",
    label: "EURIKZKA",
  },
  {
    value: "GCVPKZ2A",
    label: "GCVPKZ2A",
  },
  {
    value: "HCSKKZKA",
    label: "HCSKKZKA",
  },
  {
    value: "HLALKZKZ",
    label: "HLALKZKZ",
  },
  {
    value: "HSBKKZKX",
    label: "HSBKKZKX",
  },
  {
    value: "ICBKKZKX",
    label: "ICBKKZKX",
  },
  {
    value: "INEARUMM",
    label: "INEARUMM",
  },
  {
    value: "INLMKZKA",
    label: "INLMKZKA",
  },
  {
    value: "IRTYKZKA",
    label: "IRTYKZKA",
  },
  {
    value: "KCCJKZKK",
    label: "KCCJKZKK",
  },
  {
    value: "KCJBKZKX",
    label: "KCJBKZKX",
  },
  {
    value: "KICEKZKX",
    label: "KICEKZKX",
  },
  {
    value: "KINCKZKA",
    label: "KINCKZKA",
  },
  {
    value: "KKMFKZ2A",
    label: "KKMFKZ2A",
  },
  {
    value: "KPSTKZKA",
    label: "KPSTKZKA",
  },
  {
    value: "KSNVKZKA",
    label: "KSNVKZKA",
  },
  {
    value: "KZIBKZKA",
    label: "KZIBKZKA",
  },
  {
    value: "NBRKKZKX",
    label: "NBRKKZKX",
  },
  {
    value: "NURSKZKX",
    label: "NURSKZKX",
  },
  {
    value: "SHBKKZKA",
    label: "SHBKKZKA",
  },
  {
    value: "TSESKZKA",
    label: "TSESKZKA",
  },
  {
    value: "VTBAKZKZ",
    label: "VTBAKZKZ",
  },
  {
    value: "ZAJSKZ22",
    label: "ZAJSKZ22",
  },
];
