import axios, { AxiosResponse } from "axios";
import { AuthResponse } from "./response";
import i18n from "../../i18n";

export const getProductCardByNTIN = async (ntin:any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_NKT_SERVICE}/product-cards/search-by-ntin/${ntin}`,
      {
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching product card:", error);
    throw error;
  }
};
