import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import style from './index.module.css';

interface IAction {
  title: string;
  action: string | ((props: any) => void);
}

interface IActionDropwdownCell {
  id: number;
  actions: IAction[];
  activeTooltipId: number | null;
  onTooltipToggle: (id: number) => void;
}

export const ActionDropwdownCell: React.FC<IActionDropwdownCell> = ({
  id,
  actions,
  activeTooltipId,
  onTooltipToggle,
}) => {
  const isTooltipActive = activeTooltipId === id;

  return (
    <div className="w-7 relative">
      <button
        onClick={() => onTooltipToggle(id)}
        className={classNames('h-6 text-sm items-center gap-1 flex')}
      >
        <img
          src="/icons/ellipsis-vertical.svg"
          className="block h-6 w-6 duration-200 group-hover:brightness-200 group-hover:invert-1"
        />
      </button>

      {isTooltipActive && (
        <div
  className={classNames(
    style.tooltip,
    'absolute z-[1000000000000000000000000000] mt-2 right-full', // Изменено на right-full
    'flex flex-col min-w-[208px] p-0 rounded-lg drop-shadow-md'
  )}
>

          {actions?.map((item, index) => (
            <div key={`action-${item?.title}-${index}`} className="flex">
              {typeof item?.action === 'string' ? (
                <Link
                  to={item?.action}
                  className={classNames(
                    'flex w-full h-10 items-center text-sm justify-between'
                  )}
                >
                  <button
                    className={classNames(
                      'flex w-full h-10 items-center px-4 duration-200 text-sm justify-between'
                    )}
                    onClick={() => {
                      console.log(item?.title);
                    }}
                  >
                    <span>{item?.title}</span>
                  </button>
                </Link>
              ) : (
                <button
                  onClick={item?.action}
                  className={classNames(
                    'flex w-full h-10 items-center px-4 duration-200 text-sm justify-between'
                  )}
                >
                  <span>{item?.title}</span>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionDropwdownCell;
