import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export interface IStepperProps {
  currentStep?: number;
  steps?: string[];
}

export const Stepper: React.FC<IStepperProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [steps, setSteps] = useState([
    t("components.common_values"),
    t("components.items"),
    t("components.signing"),
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);

  const params = useParams();
  // const navigate = useNavigate();

  const step = params["*"] || "";

  useEffect(() => {
    props.steps && setSteps(props.steps);
    props.currentStep && setCurrentStep(props.currentStep);

    if (props.currentStep) {
      currentStep === steps?.length
        ? setComplete(true)
        : setCurrentStep(props.currentStep);
    }
  }, [props.steps, props.currentStep]);

  // useEffect(() => {
  //   if (step.startsWith("step-")) {
  //     const stepCount = parseInt(step.replace(/\D/g, ""), 10);
  //     if (!isNaN(stepCount)) {
  //       stepCount === steps?.length
  //         ? setComplete(true)
  //         : setCurrentStep(stepCount);
  //     }
  //   }
  // }, [step]);

  // const handleReplace = (i: number) => {
  //   const currentPath = window.location.pathname;
  //   const pathBeforeB = currentPath.split("/step")[0];
  //   const newPath = `${pathBeforeB}/step-${i + 1}`;

  //   // Replace the current route with the modified path
  //   navigate(newPath, { replace: true });
  // };

  return (
    <>
      <div className="w-full flex justify-between max-w-full relative before:-translate-y-1/2 before:w-full before:bg-slate-300 before:absolute before:top-1/2 before:left-0 before:content-[''''] before:h-px">
        <div className="bg-slate-300 h-px left-0 absolute top-1/2 -translate-y-1/2 transition ease-in-out delay-150"></div>
        {steps?.map((step, i) => (
          <>
            <div
              // type="button"
              // onClick={() => handleReplace(i)}
              key={i}
              className={`w-6 h-6 flex items-center justify-center z-10 relative rounded-full font-semibold text-white ${complete ? "bg-green" : currentStep === i + 1 ? "bg-green" : "bg-slate-300"}`}
            >
              {i + 1}
            </div>
            <span className="text-sm bg-slate-100 px-2 mx-2 whitespace-normal	overflow-hidden text-ellipsis bac z-10">
              {step}
            </span>
          </>
        ))}
      </div>
    </>
  );
};

export default Stepper;
