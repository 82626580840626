import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import style from "./file.module.css";
import { useTranslation } from "react-i18next";

const MAX_UPLOAD_SIZE = 60 * 1024 * 1024; // 6MB in bytes

export type IFileUpload = {
  setUploadedFiles: Dispatch<SetStateAction<any>>;
  uploadedFiles: any[];
};
const FileUpload: React.FC<IFileUpload> = ({
  setUploadedFiles,
  uploadedFiles,
}) => {
  const { t, i18n } = useTranslation();

  const [size, setSize] = useState<number>(0);

  function checkFileSize(uploadedFiles: FileList | null) {
    if (!uploadedFiles) return;
    let totalSize = 0;

    for (let i = 0; i < uploadedFiles?.length; i++) {
      totalSize += uploadedFiles[i].size;
    }

    setSize(totalSize);
    return totalSize;
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    files && setUploadedFiles([files[0]]);
    // files && setUploadedFiles((prevFiles: any) => [...prevFiles, files[0]]);
    checkFileSize(files || null);
  };

  const handleDeleteClick = (index: number) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
  };

  return (
    <>
      <div className="flex flex-wrap gap-[20px] w-[100%] ">
        <div className={style.inputField3}>
          {/* <div className={style.labelAndButtonWrapper}>
            <label className={style.inputLabel}>
              Сопроводительные документы
            </label>
          </div> */}
          <div className={style.customFileInput}>
            <span>+ {t("components.choose_file")}</span>
            <input
              type="file"
              name="fileInput"
              className={style.selectInput}
              onClick={(event: any) => (event.target.value = "")}
              onChange={handleFileChange}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              disabled={MAX_UPLOAD_SIZE <= size || uploadedFiles?.length > 10}
            />
          </div>
        </div>

        {uploadedFiles && (
          <ul className="w-[100%]">
            {uploadedFiles?.map((file, index) => (
              <div key={index} className={style.inputField4}>
                <div className={style.labelAndButtonWrapper}>
                  <div className={style.inputLabel}>{t("components.files")}</div>
                </div>
                <div className={style.fileName}>
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[70%]">
                    {file?.name}
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => handleDeleteClick(index)}
                  >
                    <img
                      src="/icons/trash.svg"
                      alt="Delete"
                      className="max-"
                    ></img>
                  </div>
                </div>
              </div>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default FileUpload;
