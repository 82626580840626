import axios from "axios";
import i18n from "../../i18n";

interface DocumentXMLResponse {
  status: string;
  message: string;
  data: any;  // Типизация может быть более детализирована в зависимости от структуры данных
}


interface DocumentSignedResponse {
    status: string;
    message: string;
    data: any;  // Типизация может быть более детализирована в зависимости от структуры данных
  }
  
  export const AcceptRejectDocumentXML = async (docId: any,type:string): Promise<DocumentSignedResponse> => {
    try {
  
      const response = await axios.get(`${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/get/xml/${docId}?doc_operation=${type}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true
      });
  
      return response.data;
    } catch (error) {
      console.error("Error during saving signed document XML:", error);
      throw error;
    }
  };
  
  
  export const postAcceptRejectXML = async (docId: any, signedXML: string,accept:boolean): Promise<DocumentSignedResponse> => {
    try {
      const requestBody = {
        signedXML
      };
      const response = await axios.post(`${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/accept-reject-act/${docId}?accept=${accept}`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",

        },
        withCredentials: true
      });
  
      return response.data;
    } catch (error) {
      console.error("Error during saving signed document XML:", error);
      throw error;
    }
  };
 
  