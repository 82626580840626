import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { searchByIin } from "../../../common/api/authApi";
import DocumentsService from "../../../common/api/documentsApi";
import { createDocument } from "../../../common/api/notificationApi";
import { GetReferenceService } from "../../../common/api/ordersApi";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import CalendarSelect from "../../../shared/Ui/Calendar/CalendarSelect";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "../../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import Stepper from "../../../shared/components/Stepper/Stepper";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import {
  deleteDataDocument,
  saveDataDocument,
} from "../../../shared/slice/documentState";
import style from "./DocumentImportEAEU.module.css";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";
import { FaExclamationCircle } from "react-icons/fa";
import Loader from "../../../shared/Ui/Loader/Loader";
import CalendarSelectFormik from "../../../shared/Ui/Calendar/ CalendarSelectFormik";

function DocumentImportEAEU() {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const dispatch = useAppDispatch();
  const documentState = useAppSelector((state) => state.documentState);
  const navigate = useNavigate();
  const { importEaeuDocumentId } = useParams();
  const userData = useAppSelector((state) => state.auth.userData);
  const [countryExport, setCountryExport] = useState([]);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const [organizationName, setOrganizationName] = useState({
    nameRu: "",
    nameKk: "",
  });
  const [initialValues, setInitialValues] = useState<any>({
    refEaeuCountry: null,
    manufacturerName: null,
    customsDecisionDocNumber: null, // Регистрационный номер документа
    customsClearanceRegNumber: null, // Ти документа(справ.)
    customsClearanceRegAt: null, // Дата регистрации документа
    customsDecisionAt: null, // Дата решения
    protocolAt: null, // Дата протокола испытания
    protocolNumber: null, // Номер протокола испытания
    expertAt: null, // Дата экспертного заключения
    expertNumber: null, // Номер экспертного заключения
    controlActNumber: null, // Номер Акта государственного контроля
    controlActAt: null, // Дата Акта государственного контроля
    refCustomsDecisionCode: null, // Код таможенного органа(справ.)
    docNumber: null, // Номер документа
    note: null, // Примечание
    senderBinEAEU: null,
    orgId: null,
    createUser: null,
    createdAt: null,
    // updatedAt: "",
    // signingAt: "",
    receiver: "",
    sender: "",
    status: "new",

    id: "", // ИД
    refDocType: 4,

    refCountryExport: null,
    hasPaperInfo: false,
    documentPaperNumber: null, // Hoмер бум. документа
    documentPaperAt: null, //Дата бум. документа
    document_id: "", // ИД документа
    examinationReportNumber: null,
    examinationReportDate: null,
  });

  const formValidationSchema = Yup.object().shape({
    refEaeuCountry: Yup.string().required(t("documentsError.refEaeuCountry")),

    documentPaperAt: Yup.string().required(t("documentsError.documentPaperAt")),
    controlActNumber: Yup.string()
      .nullable()
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    senderBinEAEU: Yup.string()
      .when("refEaeuCountry", (refEaeuCountry) => {
        if (refEaeuCountry[0] == 136) {
          return (
            refEaeuCountry[0] &&
            Yup.string()
              .required(t("documentsError.required"))
              ?.length(
                14,
                t("documentsError.number_of_characters", { count: 14 }),
              )
          );
        }
        if (refEaeuCountry[0] == 54) {
          return (
            refEaeuCountry[0] &&
            Yup.string()
              .required(t("documentsError.required"))
              ?.length(
                9,
                t("documentsError.number_of_characters", { count: 9 }),
              )
          );
        }
        if (refEaeuCountry[0] == 38) {
          return (
            refEaeuCountry[0] &&
            Yup.string()
              .required(t("documentsError.required"))
              ?.length(
                8,
                t("documentsError.number_of_characters", { count: 8 }),
              )
          );
        }
        if (refEaeuCountry[0] == 197) {
          return (
            refEaeuCountry[0] &&
            Yup.string()
              .required(t("documentsError.required"))
              .test(
                "length-check",
                t("documentsError.number_of_characters_or", {
                  count1: 10,
                  count2: 12,
                }),
                (value: any) =>
                  value && (value?.length === 10 || value?.length === 12),
              )
          );
        }
      })
      .required(t("documentsError.required")),
    manufacturerName: Yup.string()
      .required(t("documentsError.required"))
      .min(3, t("documentsError.minCharacters", { count: 3 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    protocolNumber: Yup.string()
      .nullable()
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    expertNumber: Yup.string()
      .nullable()
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    examinationReportNumber: Yup.string()
      .required(t("documentsError.required"))
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
    note: Yup.string()
      .nullable()
      .min(3, t("documentsError.minCharacters", { count: 3 }))
      .max(256, t("documentsError.maxCharacters", { count: 256 })),
    examinationReportDate: Yup.string().required(t("documentsError.required")),
    documentPaperNumber: Yup.string()
      .nullable()
      .min(1, t("documentsError.minCharacters", { count: 1 }))
      .max(128, t("documentsError.maxCharacters", { count: 128 })),
  });

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: "documentStatus.error" };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      default:
        return t("documentType.unknown");
    }
  }

  useEffect(() => {
    const { type, label } = getStatusType(initialValues.status);
    setStatusType({ type, label });
  }, [initialValues]);

  function onLoad() {
    if (!importEaeuDocumentId) return Promise.resolve();
    setLoading(true);

    return DocumentsService.getDocument(importEaeuDocumentId)
      .then((documentResponse) => {
        if (documentResponse && documentResponse?.data) {
          console.log(
            "Загрузка информации о документе о ввозе товаров из третьих стран:",
            documentResponse,
          );
          setInitialValues(documentResponse?.data);

          searchByIin(documentResponse?.data?.receiver)
            .then((organizationNameResponse) => {
              setOrganizationName({
                nameRu: organizationNameResponse?.data[0]?.nameRu,
                nameKk: organizationNameResponse?.data[0]?.nameKk,
              });
            })
            .catch((error) => {
              console.error(
                "Ошибка при загрузке данных об отправителе:",
                error,
              );
            });
        } else {
          console.log("Документ не найден или данные недоступны.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Ошибка при загрузке информации о документе о ввозе товаров из третьих стран:",
          error,
        );
        navigate("/404");
        setLoading(false);
      });
  }

  console.log(role);

  useEffect(() => {
    const roleObj = () => {
      return {
        roleDoc: roleMatrix?.roleMatrix?.find((item) => item.id === 4)
          .specificAction.documents[2],
        roleDraft: roleMatrix?.roleMatrix?.find((item) => item.id === 6),
      };
    };

    setRole(roleObj);

    if (importEaeuDocumentId !== undefined) {
      onLoad().then(() => {
        if (documentState.isSafe) {
          setIsFormChanged(true);
          setInitialValues((prev: any) => {
            return { ...prev, ...documentState.documentData };
          });
        }
      });
    } else {
      setLoading(false);
    }
    if (!importEaeuDocumentId && documentState.isSafe) {
      setIsFormChanged(true);
      setInitialValues((prev: any) => {
        return { ...prev, ...documentState.documentData };
      });
    }
    GetReferenceService("custom/eaeu-countries/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setCountryExport(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
  }, [importEaeuDocumentId]);

  const handleChange = (e: any) => {
    setInitialValues((prev: any) => {
      return { ...prev, hasPaperInfo: !prev.hasPaperInfo };
    });
  };

  console.log(initialValues);

  async function submitCreate(formikValues: any) {
    const backendData = {
      refEaeuCountry: formikValues.refEaeuCountry || null,
      senderBinEAEU: formikValues.senderBinEAEU || null,
      manufacturerName: formikValues.manufacturerName || null,
      controlActNumber: formikValues.controlActNumber || null,
      controlActAt: formikValues.controlActAt || null,
      protocolNumber: formikValues.protocolNumber || null,
      protocolAt: formikValues.protocolAt || null,
      expertNumber: formikValues.expertNumber || null,
      expertAt: formikValues.expertAt || null,
      note: formikValues.note || null,
      hasPaperInfo: formikValues.hasPaperInfo || false,
      documentPaperNumber: formikValues.documentPaperNumber || null,
      documentPaperAt: formikValues.documentPaperAt || null,
      marks: [],
      refDocType: 4,
      id: importEaeuDocumentId || null,
      examinationReportNumber: formikValues.examinationReportNumber || null,
      examinationReportDate: formikValues.examinationReportDate || null,
    };

    if (importEaeuDocumentId !== undefined) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          setIsFormChanged(false);
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    } else {
      await createDocument(backendData)
        .then((response) => {
          setIsFormChanged(false);
          navigate(`/documents/import-eaeu/${response.data.document.id}`);
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Проверяем состояние кнопки "Сохранить"
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormChanged !== false &&
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("import-eaeu"),
  );

  if (loading && !role) return <Loader />;

  if (!role || !role?.roleDraft?.view) {
    return <PageNotRole />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formikValues) => submitCreate(formikValues)}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={formValidationSchema}
    >
      {(formik) => (
        <Form className="max-w-[1585px] w-full m-t-[20px]">
          {/* <Loader/> */}
          {blocker && blocker.state === "blocked" ? (
            <ModalComponent
              isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
              onClose={() => {
                if (blocker && typeof blocker.reset === "function") {
                  blocker.reset();
                } else {
                  console.error("Reset function is not available.");
                }
              }}
              headerContent={t("documentNotification.notification")}
              content={
                <p>{t("documentNotification.unsaved_changes_warning")}</p>
              }
              customContent={
                <>
                  <ModalButton
                    type="button"
                    color="green"
                    onClick={() => {
                      if (blocker && typeof blocker.proceed === "function") {
                        blocker.proceed();
                      } else {
                        console.error("Proceed function is not available.");
                      }
                    }}
                  >
                    {t("mainButtons.continue")}
                  </ModalButton>
                  <ModalButton
                    type="button"
                    onClick={() => {
                      if (blocker && typeof blocker.reset === "function") {
                        blocker.reset();
                      } else {
                        console.error("Reset function is not available.");
                      }
                    }}
                  >
                    {t("mainButtons.cancel")}
                  </ModalButton>
                </>
              }
            />
          ) : null}
          <div className="flex flex-col  gap-[20px]  ">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <div>
                {importEaeuDocumentId ? (
                  <div className="flex gap-[20px]">
                    <div className="flex flex-col">
                      <StatusBadge type={statusType.type}>
                        {t("documentLabels.status") + ": " + statusType.label}
                      </StatusBadge>
                      {(initialValues?.signingAt ||
                        initialValues?.createdAt) && (
                        <div className="text-[14px] font-semibold text-gray-700 ml-[9px]">
                          {format(
                            new Date(
                              initialValues?.signingAt ||
                                initialValues?.createdAt,
                            ),
                            "dd.MM.yyyy, HH:mm",
                          )}
                        </div>
                      )}
                    </div>
                    <span className="flex gap-[10px]">
                      <div>{getDescription(formik.values.refDocType)}:</div>
                      <div>
                        {formik.values.docNumber
                          ? formik.values.docNumber
                          : " " + t("mainLabels.not_generated")}
                      </div>
                    </span>
                  </div>
                ) : (
                  <div className="flex gap-[20px]">
                    <StatusBadge type={statusType.type}>
                      {t("documentLabels.status") + " " + statusType.label}
                    </StatusBadge>
                    <div>
                      {t("documentLabels.create_document")}
                      {": "}
                      {t("documentType.notification_from_eaes")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-between">
              <div className="my-auto">
                <Stepper />
              </div>

              <div className="flex flex-row gap-[20px] flex-wrap">
                {/* Показываем кнопки только после завершения загрузки */}
                {!loading && (
                  <>
                    {/* Кнопка "Закрыть" */}
                    <button
                      className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        dispatch(deleteDataDocument());
                        // if (initialValues.status == "DRAFT"){
                        //   navigate("/documents/drafts");
                        // } else {
                        //   navigate("/documents/inbox");
                        // }
                        if (initialValues.status == "DRAFT") {
                          console.log("Navigating to drafts");
                          navigate("/documents/drafts");
                        } else if (initialValues.status == "new") {
                          console.log("Navigating to sent");
                          navigate("/documents/inbox");
                        } else if (userData.iinBin !== initialValues.receiver) {
                          console.log("Navigating to sent");
                          navigate("/documents/sent");
                        } else {
                          console.log("Navigating to inbox");
                          navigate("/documents/inbox");
                        }
                      }}
                    >
                      {t("mainButtons.close")}
                    </button>

                    {/* Проверка статуса: "new" или "DRAFT" */}
                    {((formik.values.status === "new" && role.roleDraft.edit) ||
                      (formik.values.status == "DRAFT" &&
                        role.roleDraft.edit)) && (
                      <div className="flex flex-row gap-[20px] flex-wrap">
                        {/* Кнопка "Проверить завершение" */}
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                          type="submit"
                        >
                          {t("documentLabels.check_completion")}
                        </button>

                        {/* Кнопка "Сохранить" */}
                        <button
                          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                          type="button"
                          onClick={() => {
                            dispatch(deleteDataDocument());
                            submitCreate(formik.values);
                          }}
                        >
                          {t("mainButtons.save")}
                        </button>
                      </div>
                    )}

                    {/* Кнопка "Далее" */}
                    <button
                      className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                      type="button"
                      onClick={() => {
                        if (isFormChanged) {
                          dispatch(saveDataDocument(formik.values));
                        }
                        navigate("orders");
                      }}
                    >
                      {t("mainButtons.next")}
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col bg-white px-5 pb-5 relative rounded-xl shadow-md  gap-[20px]">
              <div className="flex flex-wrap gap-[20px] "></div>
              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <span className={style.labelSpan}>
                      {t("documentLabels.iinBin") +
                        ", " +
                        t("documentLabels.organization_name_receiver")}
                    </span>
                  </div>
                  <span className={style.spanValue}>
                    {importEaeuDocumentId
                      ? formik?.values?.receiver
                      : userData?.iinBin || "-"}
                    ,{" "}
                    {importEaeuDocumentId
                      ? locale === "ru"
                        ? organizationName?.nameRu
                        : locale === "kk"
                          ? organizationName?.nameKk
                          : "-"
                      : userData?.organizationName || "-"}
                  </span>
                </div>
              </div>

              <div className="flex gap-[20px] flex-wrap">
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("notification_from_eaes.country_label")}*
                    </label>
                    {/* {formik.touched.refEaeuCountry &&
                      formik.errors.refEaeuCountry && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.refEaeuCountry === "string"
                            ? formik.errors.refEaeuCountry
                            : t("notification_from_eaes.country_error")}
                        </div>
                      )} */}
                    {formik.touched.refEaeuCountry &&
                      formik.errors.refEaeuCountry && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.refEaeuCountry === "string"
                              ? formik.errors.refEaeuCountry
                              : t("notification_from_eaes.country_error")}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    value={formik.values.refEaeuCountry}
                    component={SelectCustomFormik}
                    name="refEaeuCountry"
                    placeholder={t("documentLabels.choose")}
                    size="sm"
                    options={countryExport}
                    isDisabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(selectedOption: any) => {
                      const newValue = selectedOption
                        ? selectedOption.value
                        : "";
                      setIsFormChanged(true);
                    }}
                  />
                </div>

                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("notification_from_eaes.bin_label")}*
                    </label>
                    {/* {formik.touched.senderBinEAEU &&
                      formik.errors.senderBinEAEU && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.senderBinEAEU === "string"
                            ? formik.errors.senderBinEAEU
                            : t("notification_from_eaes.bin_error")}
                        </div>
                      )} */}
                    {formik.touched.senderBinEAEU &&
                      formik.errors.senderBinEAEU && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.senderBinEAEU === "string"
                              ? formik.errors.senderBinEAEU
                              : t("notification_from_eaes.bin_error")}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="senderBinEAEU"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.senderBinEAEU}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("notification_from_eaes.manufacturer_label")}*
                    </label>
                    {/* {formik.touched.manufacturerName &&
                      formik.errors.manufacturerName && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.manufacturerName === "string"
                            ? formik.errors.manufacturerName
                            : t("notification_from_eaes.manufacturer_error")}
                        </div>
                      )} */}
                    {formik.touched.manufacturerName &&
                      formik.errors.manufacturerName && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.manufacturerName === "string"
                              ? formik.errors.manufacturerName
                              : t("notification_from_eaes.manufacturer_error")}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="manufacturerName"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.manufacturerName}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>
              <span className="font-semibold text-[17px]">
                {t("notification_from_eaes.document_info_label")}
              </span>
              <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("notification_from_eaes.control_act_number_label")}
                    </label>
                    {/* {formik.touched.controlActNumber &&
                      formik.errors.controlActNumber && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.controlActNumber === "string"
                            ? formik.errors.controlActNumber
                            : t(
                                "notification_from_eaes.control_act_number_error",
                              )}
                        </div>
                      )} */}
                    {formik.touched.controlActNumber &&
                      formik.errors.controlActNumber && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.controlActNumber === "string"
                              ? formik.errors.controlActNumber
                              : t(
                                  "notification_from_eaes.control_act_number_error",
                                )}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="controlActNumber"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.controlActNumber}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
                <div className={style.data_input}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("notification_from_eaes.control_act_date_label")}{" "}
                    </label>
                  </div>
                  <CalendarSelectFormik
                    name="controlActAt"
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={() => setIsFormChanged(true)}
                  />
                  {/* {formik.touched.controlActAt &&
                    formik.errors.controlActAt && (
                      <div className={style.errorLabel}>
                        {typeof formik.errors.controlActAt === "string"
                          ? formik.errors.controlActAt
                          : t("notification_from_eaes.control_act_date_error") +
                            " *"}
                      </div>
                    )} */}
                  {formik.touched.controlActAt &&
                    formik.errors.controlActAt && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.controlActAt === "string"
                            ? formik.errors.controlActAt
                            : t(
                                "notification_from_eaes.control_act_date_error",
                              ) + " *"}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("notification_from_eaes.protocol_number_label")}
                    </label>
                    {/* {formik.touched.protocolNumber &&
                      formik.errors.protocolNumber && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.protocolNumber === "string"
                            ? formik.errors.protocolNumber
                            : t(
                                "notification_from_eaes.protocol_number_error",
                              ) + " *"}
                        </div>
                      )} */}
                    {formik.touched.protocolNumber &&
                      formik.errors.protocolNumber && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.protocolNumber === "string"
                              ? formik.errors.protocolNumber
                              : t(
                                  "notification_from_eaes.protocol_number_error",
                                ) + " *"}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="protocolNumber"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.protocolNumber}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
                <div className={style.data_input}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("notification_from_eaes.protocol_date_label")}
                    </label>
                    {/* {formik.touched.protocolAt && formik.errors.protocolAt && (
                      <div className={style.errorLabel}>
                        {typeof formik.errors.protocolAt === "string"
                          ? formik.errors.protocolAt
                          : t("notification_from_eaes.protocol_date_error") +
                            " *"}
                      </div>
                    )} */}
                    {formik.touched.protocolAt && formik.errors.protocolAt && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.protocolAt === "string"
                            ? formik.errors.protocolAt
                            : t("notification_from_eaes.protocol_date_error") +
                              " *"}
                        </div>
                      </div>
                    )}
                  </div>
                  <CalendarSelectFormik
                    name="protocolAt"
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={() => setIsFormChanged(true)}
                  />
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("notification_from_eaes.expert_number_label")}
                    </label>
                    {/* {formik.touched.expertNumber &&
                      formik.errors.expertNumber && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.expertNumber === "string"
                            ? formik.errors.expertNumber
                            : t("notification_from_eaes.expert_number_error") +
                              "*"}
                        </div>
                      )} */}
                    {formik.touched.expertNumber &&
                      formik.errors.expertNumber && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.expertNumber === "string"
                              ? formik.errors.expertNumber
                              : t(
                                  "notification_from_eaes.expert_number_error",
                                ) + "*"}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="expertNumber"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.expertNumber}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
                <div className={style.data_input}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("notification_from_eaes.expert_date_label")}
                    </label>
                    {/* {formik.touched.expertAt && formik.errors.expertAt && (
                      <div className={style.errorLabel}>
                        {typeof formik.errors.expertAt === "string"
                          ? formik.errors.expertAt
                          : t("notification_from_eaes.expert_date_error") +
                            " *"}
                      </div>
                    )} */}
                    {formik.touched.expertAt && formik.errors.expertAt && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.expertAt === "string"
                            ? formik.errors.expertAt
                            : t("notification_from_eaes.expert_date_error") +
                              " *"}
                        </div>
                      </div>
                    )}
                  </div>
                  <CalendarSelectFormik
                    name="expertAt"
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={() => setIsFormChanged(true)}
                  />
                </div>
              </div>
              <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                <div className={style.inputField1}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("notification_from_eaes.examinationReportNumber")}
                    </label>
                    {/* {formik.touched.examinationReportNumber &&
                      formik.errors.examinationReportNumber && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.examinationReportNumber ===
                          "string"
                            ? formik.errors.examinationReportNumber
                            : t(
                                "notification_from_eaes.examinationReportNumber_error",
                              ) + "*"}
                        </div>
                      )} */}
                    {formik.touched.examinationReportNumber &&
                      formik.errors.examinationReportNumber && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.examinationReportNumber ===
                            "string"
                              ? formik.errors.examinationReportNumber
                              : t(
                                  "notification_from_eaes.examinationReportNumber_error",
                                ) + "*"}
                          </div>
                        </div>
                      )}
                  </div>
                  <Field
                    type="text"
                    name="examinationReportNumber"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik.values.examinationReportNumber}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
                <div className={style.data_input}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel2}>
                      {t("notification_from_eaes.examinationReportDate")}
                    </label>
                    {/* {formik.touched.examinationReportDate &&
                      formik.errors.examinationReportDate && (
                        <div className={style.errorLabel}>
                          {typeof formik.errors.examinationReportDate ===
                          "string"
                            ? formik.errors.examinationReportDate
                            : t(
                                "notification_from_eaes.examinationReportDate_error",
                              ) + " *"}
                        </div>
                      )} */}
                    {formik.touched.examinationReportDate &&
                      formik.errors.examinationReportDate && (
                        <div className={style.tooltipWrapper}>
                          <FaExclamationCircle className={style.errorIcon} />
                          <div className={style.tooltipText}>
                            {typeof formik.errors.examinationReportDate ===
                            "string"
                              ? formik.errors.examinationReportDate
                              : t(
                                  "notification_from_eaes.examinationReportDate_error",
                                ) + " *"}
                          </div>
                        </div>
                      )}
                  </div>
                  <CalendarSelectFormik
                    name="examinationReportDate"
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={() => setIsFormChanged(true)}
                  />
                </div>
              </div>

              <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                <div className={style.inputField2}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("notification_from_eaes.note")}
                    </label>
                    {formik.touched.note && formik.errors.note && (
                      <div className={style.tooltipWrapper}>
                        <FaExclamationCircle className={style.errorIcon} />
                        <div className={style.tooltipText}>
                          {typeof formik.errors.note === "string"
                            ? formik.errors.note
                            : t(
                                "notification_from_eaes.examinationReportDate_error",
                              ) + " *"}
                        </div>
                      </div>
                    )}
                  </div>
                  <Field
                    as="textarea"
                    name="note"
                    placeholder={t("documentLabels.choose_option")}
                    className={`${style.textareaPlaceholder} textarea-placeholder w-full h-[45px] min-h-[45px] border border-[#a3c0d2] rounded-lg px-4 pt-3 text-sm text-[#2e3138] font-medium leading-5 outline-none`}
                    value={formik.values.note}
                    disabled={
                      (formik.values.status !== "new" &&
                        formik.values.status !== "DRAFT") ||
                      !role.roleDraft.edit
                    }
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex gap-[20px]">
                <CheckBox
                  id="myCheckbox"
                  checked={formik.values.hasPaperInfo}
                  onChange={() =>
                    formik.setFieldValue(
                      "hasPaperInfo",
                      !formik.values.hasPaperInfo,
                    )
                  }
                />

                <span className="font-semibold text-[17px]">
                  {t("notification_from_eaes.paper_info_label")}
                </span>
              </div>
              {formik.values.hasPaperInfo && (
                <div className="flex gap-[20px] flex-wrap max-w-[1080px]">
                  <div className={style.inputField1}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t("notification_from_eaes.document_number_label")}
                      </label>
                      {/* {formik.touched.documentPaperNumber &&
                        formik.errors.documentPaperNumber && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.documentPaperNumber ===
                            "string"
                              ? formik.errors.documentPaperNumber
                              : t(
                                  "notification_from_eaes.document_number_error",
                                )}
                          </div>
                        )} */}
                      {formik.touched.documentPaperNumber &&
                        formik.errors.documentPaperNumber && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.documentPaperNumber ===
                              "string"
                                ? formik.errors.documentPaperNumber
                                : t(
                                    "notification_from_eaes.document_number_error",
                                  )}
                            </div>
                          </div>
                        )}
                    </div>
                    <Field
                      type="text"
                      name="documentPaperNumber"
                      placeholder={t("documentLabels.choose_option")}
                      className={style.placeholder}
                      value={formik.values.documentPaperNumber}
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        setIsFormChanged(true);
                      }}
                    />
                  </div>
                  <div className={style.data_input}>
                    <div className={style.labelAndButtonWrapper}>
                      <label className={style.inputLabel2}>
                        {t("notification_from_eaes.document_date_label")}
                      </label>
                      {/* {formik.touched.documentPaperAt &&
                        formik.errors.documentPaperAt && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.documentPaperAt === "string"
                              ? formik.errors.documentPaperAt
                              : t(
                                  "notification_from_eaes.document_date_error",
                                ) + " *"}
                          </div>
                        )} */}
                      {formik.touched.documentPaperAt &&
                        formik.errors.documentPaperAt && (
                          <div className={style.tooltipWrapper}>
                            <FaExclamationCircle className={style.errorIcon} />
                            <div className={style.tooltipText}>
                              {typeof formik.errors.documentPaperAt === "string"
                                ? formik.errors.documentPaperAt
                                : t(
                                    "notification_from_eaes.document_date_error",
                                  ) + " *"}
                            </div>
                          </div>
                        )}
                    </div>
                    <CalendarSelectFormik
                      name="documentPaperAt"
                      disabled={
                        (formik.values.status !== "new" &&
                          formik.values.status !== "DRAFT") ||
                        !role.roleDraft.edit
                      }
                      onChange={() => setIsFormChanged(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
        </Form>
      )}
    </Formik>
  );
}

export default DocumentImportEAEU;
