import React from "react";
import styles from "./Status-badge.module.css";

interface StatusBadgeProps {
  children: React.ReactNode;
  type?: string;
  scale?: number;
  fontSize?: string; 
  circleSize?: string;
}

function StatusBadge({ children, type, scale = 1,fontSize = '16px', circleSize = '8px' }: StatusBadgeProps) {
  const badgeClass =
    type === "active"
      ? `${styles.badge} ${styles["badge-active"]}`
      : type === "test"
        ? `${styles.badge} ${styles["badge-test"]}`
        : type === "error"
          ? `${styles.badge} ${styles["badge-error"]}`
          : styles.badge;

  const circleClass =
    type === "active"
      ? `${styles.circle} ${styles["circle-active"]}`
      : type === "test"
        ? `${styles.circle} ${styles["circle-test"]}`
        : type === "error"
          ? `${styles.circle} ${styles["circle-error"]}`
          : styles.circle;

          const circleStyle = {
            width: circleSize,
            height: circleSize,
            backgroundColor: type === "active"
              ? '#30bb67'
              : type === "test"
                ? '#ffa722'
                : type === "error"
                  ? '#d83a3a'
                  : '#678aa1'
          };
          

          const style = {
            transform: `scale(${scale})`, 
            fontSize: fontSize, // Применение размера шрифта из props
          };

  return (
    <div className={badgeClass} style={style}>
      <div className={circleClass} style={circleStyle}></div>
      {children}
    </div>
  );
}

export default StatusBadge;
