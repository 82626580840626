import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SelectCustom } from "../../Ui/SelectCustom/SelectCustom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { PaginationState } from "@tanstack/react-table";
import { editPageSize } from "../../slice/userSettings";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hook/useAppSelector";
import { useTranslation } from "react-i18next";

export interface ITableProps<T> {
  totalItems: number;
  totalPages: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}

export const Pagination: React.FC<ITableProps<any>> = ({
  totalItems,
  totalPages,
  pagination,
  setPagination,
}) => {
  const { t, i18n } = useTranslation();

  const paginationOptions = [
    {
      value: 5,
      label: "5",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 50,
      label: "50",
    },
  ];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const dispatch = useDispatch();

  const renderButton = (
    onClick: () => void,
    children: React.ReactNode,
    disabled: boolean,
    className?: string,
  ) => {
    return (
      <button
        type="button"
        className={`flex items-center justify-center p-1 w-8 h-8 mx-1 cursor-pointer  text-gray-600 bg-white border rounded hover:bg-gray-100 ${className || ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  };

  return (
    <div className="flex flex-col items-center justify-between px-5 py-5 bg-transparent lg:flex-row">
      <div className="flex flex-row items-center justify-between p-1 text-left text-gray-600 font-semibold">
        <span className="px-1">{t("components.show")}</span>

        <div className="flex justify-center w-20">
          <SelectCustom
            menuIsOpen={isMenuOpen}
            // menuIsOpen
            options={paginationOptions}
            onChange={(option) => {
              setPagination((prevState) => ({
                ...prevState,
                pageIndex: 0,
                pageSize: option?.value || 10,
              }));
              dispatch(editPageSize(option?.value || 10));
            }}
            placeholder={pagination.pageSize}
            size="sm"
            isError={false}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
          />
        </div>
        <span className="px-1">
          {" "}
          {t("components.from", {totalItems: totalItems})}
        </span>
      </div>
      <div className="flex items-center p-1">
        {renderButton(
          () =>
            setPagination((prevState) => ({
              ...prevState,
              pageIndex: pagination.pageIndex - 1,
            })),
          <FaAngleLeft />,
          pagination.pageIndex - 1 < 0,
        )}

        {!(pagination.pageIndex - 1 < 0) &&
          renderButton(
            () =>
              setPagination((prevState) => ({
                ...prevState,
                pageIndex: pagination.pageIndex - 1,
              })),
            pagination.pageIndex,
            pagination.pageIndex - 1 < 0,
          )}
        {renderButton(
          () => {},
          pagination.pageIndex + 1,
          false,
          "border-2 border-green",
        )}
        {!(pagination.pageIndex + 2 > totalPages) &&
          renderButton(
            () =>
              setPagination((prevState) => ({
                ...prevState,
                pageIndex: pagination.pageIndex + 1,
              })),
            pagination.pageIndex + 2,
            pagination.pageIndex + 2 > totalPages,
          )}
        {renderButton(
          () =>
            setPagination((prevState) => ({
              ...prevState,
              pageIndex: pagination.pageIndex + 1,
            })),
          <FaAngleRight />,
          pagination.pageIndex + 2 > totalPages,
        )}
      </div>
      <div className="flex flex-row p-1">
        <span className="flex items-center gap-1 text-left text-gray-600 font-semibold">
          {t("components.go_to_page")}
          <input
            type="number"
            defaultValue={pagination.pageIndex + 1}
            max={totalPages}
            min={1}
            onChange={(e) => {
              let value = Number(e.target.value);


              if (value >= 1 && value <= totalPages) {
                const page = value - 1;
                setPagination((prevState) => ({
                  ...prevState,
                  pageIndex: page,
                }));
              }
            }}
            className="border p-1 rounded w-16 bg-transparent"
          />
          <span>{t("components.from", {totalItems: totalPages})}</span>
        </span>
      </div>
    </div>
  );
};

export default Pagination;
