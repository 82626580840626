import React from "react";
import "./index.css";
import { Provider } from "react-redux";
import store from "../store";
import { router } from "./router/AppRouter";
import {RouterProvider} from 'react-router-dom';
import { RedirectAuthorization } from "../shared/hoc";


function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;