import { createSlice } from '@reduxjs/toolkit';


interface RoleMatrixState {
    isMatrix: boolean;
    roleMatrix:any[];
}

const initialState: RoleMatrixState = {
    isMatrix: false,
    roleMatrix: [],
};

export const roleMatrix = createSlice({
    name: "roleMatrix",
    initialState,
    reducers: {
        saveRoleState: (state, action) => {
            state.isMatrix=true,
            state.roleMatrix =action.payload
        },
        deleteRoleState:(state,action)=>{
            state.isMatrix=false,
            state.roleMatrix=[]
        }
    }
});

export const { saveRoleState,deleteRoleState } = roleMatrix.actions;
export default roleMatrix.reducer;
