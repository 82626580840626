import React, { FunctionComponent, PropsWithChildren } from "react";
import SideNavbar from "./SideNavbar/SideNavbar";
import Header from "./Header";
import { SuspenseLayout } from "./SuspenseLayout";
import { Outlet } from "react-router-dom";

export const LoggedInLayout: FunctionComponent<PropsWithChildren> = () => {
  return (
    <SuspenseLayout>
      <div className="flex h-screen w-screen bg-slate-100">
        <div className="flex-none grow-0 h-full p-5">
          <SideNavbar />
        </div>
        <div className="flex flex-col h-full grow p-5 min-w-0 overflow-y-scroll">
          <Header />
          <main className="flex grow flex-1 h-full w-full min-w-0">
            <Outlet />
          </main>
        </div>
      </div>
    </SuspenseLayout>
  );
}

export default LoggedInLayout;
