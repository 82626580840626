import React, { useEffect, useState } from "react";
import { ChangingLanguage } from "../../shared/components/ChangingLanguage";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form, FieldArray } from "formik";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import style from "./autorization.module.css";
import CheckBox from "../../shared/Ui/CheckBox/CheckBox";
import * as Yup from "yup";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { generateXmlFromData } from "../../common/libs/xml/createXml";
import { authenticate, DocumentHeadReg, viewAndPrintDocumentHead } from "../../common/api/authApi";
import Cookies from "js-cookie";
import { login, updateOrganizationData } from "../../shared/slice/authSlice";
import {
  checkUser,
  createHead,
  createUser,
  fetchBankCatalogList,
} from "../../common/api/registrationApi";
import { useAppDispatch } from "../../shared/hook";
import { normalizedLocale } from "../../common/libs/normalizedLocale";
import { bicData } from "../../common/libs/mock/bicData";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";
import AlertBox from "../../shared/Ui/AlertBox/AlertBox";
import { GetReferenceService } from "../../common/api/ordersApi";
import Loader from "../../shared/Ui/Loader/Loader";

const Registration = () => {
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const [isLoading, setIsLoading] = useState(false);
  const [isUOT, setIsUOT] = useState<any>(false);
  const [ecpKey, setEcpKey] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedHead, setIsCheckedHead] = useState(false);
  const [authStartInfo, setAuthStartInfo] = useState<any>();
  const [dataBic, setDataBic] = useState<any>(bicData);
  const [dataOgd, setDataOgd] = useState<any>(bicData);
  const [dataGroup, setGroup] = useState<any>([{
    value: "1",
    label: "Ювелирные изделия",
  }]);
  const [dataNameBank, setDataNameBank] = useState<any>(bicData);
  const [errorModal, setErrorModal] = useState<any>(null);
  const [finalData, setfinalData] = useState<any>({
    signedXML: '',
    cms: '',
  });
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [isOpenModal, setIsOpenModal] = useState<any>(null)
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    IIN_BIN: "", // ИНН/БИН организации
    Organization_Name: "", // Наименование организации
    User_IIN: "", // ИИН руководителя
    User_fullName: "",
    User_Position: "", // Должность руководителя
    organizationPhone: "",
    organizationEmail: "",

    City: "",
    Street: "", // Улица
    House: "", // Дом
    Building: "", // Строение (если есть) - необязательно
    Premises: "", // Помещение (если есть) - необязательно
    refOgd: "",

    Checking_Account: "", // Расчетный счет
    Bank_Identification_Code: "", // Банковский идентификационный код
    Email: "", // E-mail
    Phone: "", // Телефон
    Organization_Website: "", // Веб-сайт организации
    Select_Role: [], // Выбрать роль

    Phisical_Iin: "",
    Phisical_name: "",
    Phisical_surname: "",
    Phisical_otcestvo: "",
    Phisical_position: "",
    refBankCatalog: "",
    kbe: "",
    refProductGroup: "1",
  });

  const positionMap = {
    HEAD: t("authorization.firstManager"),
    EMPLOYEE_CAN_SIGN: "Лицо наделенное правом подписи",
    EMPLOYEE_CAN_SIGN_FINANCIAL:
      "Лицо наделенное правом подписи финансовых документов",
    EMPLOYEE_HR: "Сотрудник отдела кадров",
    EMPLOYEE_SIMPLE: "Сотрудник организации",
  };

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleChangeHead = (e: any) => {
    setIsCheckedHead(e.target.checked);
  };

  useEffect(() => {
    GetReferenceService("/register-org/ogd/list")
      .then((response: any) => {
        const optionsOgd = response?.map((e: any) => ({
          value: e.id,
          label: e.name,
        }));

        setDataOgd(optionsOgd);
      })
      .catch((error) => {
        console.error("Error fetching bank catalog list:", error);
      });

    fetchBankCatalogList()
      .then((response: any) => {
        const optionsBic = response?.map((bank: any) => ({
          value: bank.bik,
          label: bank.bik,
        }));

        const optionsName = response?.map((bank: any) => ({
          value: bank.id,
          label: locale === "ru" ? bank.nameRu : bank.nameKk,
        }));

        setDataBic(optionsBic);
        setDataNameBank(optionsName);
      })
      .catch((error) => {
        console.error("Error fetching bank catalog list:", error);
      });

    if (authStartInfo?.status && authStartInfo.status === "SUCCESS") {
      const { subjectInfo, certificateInfo } = authStartInfo.data.signInfo;
      const iinOrBin =
        subjectInfo.subjectType === "JURIDICAL"
          ? subjectInfo.bin
          : subjectInfo.iin;
      let position = t("authorization.firstManager");
      let selectRole: number[] = []; // Значение по умолчанию

      // Определение должности на основе ключевых типов пользователей
      if (certificateInfo.keyUserTypes.includes("HEAD")) {
        position = t("authorization.firstManager");
      } else {
        position =
          certificateInfo.keyUserTypes
            ?.filter((key: any) => positionMap[key as keyof typeof positionMap])
            ?.map((key: any) => positionMap[key as keyof typeof positionMap])
            ?.join(", ") || t("authorization.firstManager");
      }

      if (authStartInfo.data.roles?.length < 2) {
        selectRole = [authStartInfo.data.roles[0].roleId];
      } else {
        selectRole = [];
      }

      if (
        !certificateInfo.keyUserTypes.includes("HEAD") &&
        authStartInfo.data.organization
      ) {
        setIsUOT(true);
        position = t("authorization.employee");
      }

      setInitialValues((prev: any) => ({
        ...prev,
        IIN_BIN: iinOrBin,
        Organization_Name:
          subjectInfo.organization ||
          `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
        User_IIN: subjectInfo.iin,
        User_fullName: `${subjectInfo.lastName} ${subjectInfo.firstName} ${subjectInfo.fatherName} `,
        User_Position: position,
        Select_Role: selectRole,
      }));
    }
  }, [authStartInfo]);

  // Валидация для случая, когда isUOT = true
  const uotValidationSchema = Yup.object().shape({
    Phisical_Iin: Yup.string().required("Это поле обязательно для заполнения"),
    Phisical_name: Yup.string().required("Это поле обязательно для заполнения"),
    Phisical_surname: Yup.string().required(
      "Это поле обязательно для заполнения",
    ),
    Phisical_otcestvo: Yup.string().required(
      "Это поле обязательно для заполнения",
    ),
    Phisical_position: Yup.string().required(
      "Это поле обязательно для заполнения",
    ),
  });

  // Валидация для случая, когда isUOT = false
  const defaultValidationSchema = Yup.object().shape({
    IIN_BIN: Yup.string()
      .required("Это поле обязательно для заполнения")
      ?.length(12, "ИИН/БИН должен содержать ровно 12 символов"),
    Organization_Name: Yup.string().required(
      "Это поле обязательно для заполнения",
    ),
    refProductGroup: Yup.string().required("Это поле обязательно для заполнения"),
    kbe: Yup.string()
      .required("Это поле обязательно для заполнения")
      ?.length(2, "КБе должно содержать ровно 2 символа"),
    refBankCatalog: Yup.string().required("Это поле обязательно для заполнения"),
    refOgd: Yup.string().required("Это поле обязательно для заполнения"),
    User_IIN: Yup.string()
      .required("Это поле обязательно для заполнения")
      ?.length(12, "ИИН должен содержать ровно 12 символов"),
    User_fullName: Yup.string().required("Это поле обязательно для заполнения"),
    User_Position: Yup.string().required("Это поле обязательно для заполнения"),
    organizationPhone: Yup.string().required(
      "Это поле обязательно для заполнения",
    ),
    organizationEmail: Yup.string()
      .email("Введите корректный email")
      .required("Это поле обязательно для заполнения"),
    City: Yup.string().required("Это поле обязательно для заполнения"),
    Street: Yup.string().required("Это поле обязательно для заполнения"),
    House: Yup.string(),
    Building: Yup.string(),
    Premises: Yup.string(),
    Checking_Account: Yup.string()
      .required("Это поле обязательно для заполнения")
      ?.length(20, "Расчётный счет должен содержать 20 символов"),
    Bank_Identification_Code: Yup.string().required(
      "Это поле обязательно для заполнения",
    ),
    Email: Yup.string()
      .email("Введите корректный email")
      .required("Это поле обязательно для заполнения"),
    Phone: Yup.string().required("Это поле обязательно для заполнения"),
  });

  async function checkFIrstAuth() {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const xmlData = `<time>${currentTimeInSeconds}</time>`;
    let xmlSignature;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xmlData,
        NCALayerClient.basicsXMLParams,
        NCALayerClient.basicsSignerTestAny,
        // basicsSignerSignAny
        "ru",
      );
      console.log("Подпись XML:", xmlSignature);
    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
    }


    function processString(inputString: string) {
      // Убираем переносы строк
      let withoutNewlines = inputString.replace(/\n/g, "");

      // Заменяем каждую двойную кавычку на \"
      let withEscapes = withoutNewlines.replace(/"/g, '\\"');

      return withEscapes;
    }


    if (xmlSignature[0]) {
      setIsLoading(true)
      checkUser(xmlSignature[0])
        .then((response) => {

          setAuthStartInfo(response);
          setEcpKey(response);
          setIsLoading(false)
        })
        .catch((error) => {
          // Проверка на наличие response и message в объекте ошибки
          const errorMessage =
            error.response?.data?.message || "Произошла ошибка";
          setErrorModal({ errorMessage });
          setIsLoading(false)
        });
    }

  }


  // Подпись с помощью Rsa Руководителя первый шаг запрос дока
  async function registrationRsaHead(xml: any) {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    let xmlSignature:any;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature);
    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
    }

    if (xmlSignature[0]) {
      setIsLoading(true)
      setfinalData((prev: any) => ({ ...prev, signedXML: xmlSignature[0] }));
      DocumentHeadReg(xmlSignature[0])
        .then((response) => {
          setIsOpenModal(response.data)
          setIsLoading(false)
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || "Произошла ошибка";
          setErrorModal({ errorMessage });
          setIsLoading(false)
        })
    }
  }

    // Подпись Самая бешенная Cms последний второй шаг подпись дока и регистрация
    async function registrationRsaHeadEnd() {
      const ncalayerClient = new NCALayerClient();
  
      try {
        await ncalayerClient.connect();
      } catch (error) {
        setAlertOpen({
          status: "error",
          message: "Не удалось подключиться к NCALayer",
        });
        return;
      }
  
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      let cmsData = `${isOpenModal[0]?.sha256Hash},${isOpenModal[1]?.sha256Hash}`
      let xmlSignature;
  
      try {
        xmlSignature = await ncalayerClient.basicsSignCMS(
          NCALayerClient.basicsStoragesAll,
          cmsData,
          NCALayerClient.basicsCMSParams,
          process.env.REACT_APP_ETRACK_TEST ? NCALayerClient.basicsSignerTestAny :  NCALayerClient.basicsSignerAny,
          // basicsSignerSignAny
          "ru",
        );
        console.log("Подпись XML:", xmlSignature);
      } catch (error) {
        console.log("Ошибка при выполнении XML-подписи:", error);
        return;
      }
  
      if (xmlSignature) {
        xmlSignature = xmlSignature
          .replace('-----BEGIN CMS-----', '')
          .replace('-----END CMS-----', '')
          .trim()
        
        createHead(finalData.signedXML, xmlSignature )
        .then((response) => {
          setAlertOpen({
            status: "SUCCESS",
            message: "Вы успешно зарегистрировались",
          });
          navigate("/authorization");
        })
        .catch((error) => {
          // Проверка на наличие response и message в объекте ошибки
          const errorMessage =
            error.response?.data?.message || "Произошла ошибка";
          setErrorModal({ errorMessage });
        });
      }
    }


  // Подпись с помощью Rsa Простого сотрудника
  async function registrationRsaUot(xml: any) {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: "Не удалось подключиться к NCALayer",
      });
      return;
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    let xmlSignature;

    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature);
    } catch (error) {
      console.log("Ошибка при выполнении XML-подписи:", error);
    }

    createUser(xmlSignature[0])
      .then((response) => {
        setAlertOpen({
          status: "SUCCESS",
          message: "Вы успешно зарегистрировались",
        });
        navigate("/authorization");
      })
      .catch((error) => {
        // Проверка на наличие response и message в объекте ошибки
        const errorMessage =
          error.response?.data?.message || "Произошла ошибка";
        setErrorModal({ errorMessage });
      });
  }

    // Это ЗАПРОС НА ФАЙЫ ДОКА DOWLOAD!
    const handleDownloadClick = async (filename: any) => {
      try {
        setIsLoading(true);
        const fileBlob = await viewAndPrintDocumentHead(filename);
        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `document_${filename}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl); // освободите ресурсы, связанные с URL
      } catch (error) {
        console.error("Ошибка при скачивании файла:", error);
      } finally {
        setIsLoading(false);
      }
    };

  // Отправка запроса
  const handleSubmit = async (values: any) => {
    const beckendData = {
      ...initialValues,

      IIN_BIN: values.Phisical_Iin || initialValues.IIN_BIN,
      User_IIN: values.Phisical_Iin || initialValues.User_IIN,
      Organization_Name:
        values.Phisical_surname || initialValues.Organization_Name,
      User_Name: values.Phisical_name || values.User_fullName.split(" ")[0],
      User_Surname:
        values.Phisical_surname || values.User_fullName.split(" ")[1],
      User_Patronymic:
        values.Phisical_otcestvo || values.User_fullName.split(" ")[2],
      city: values.City,
      street: values.Street, // Улица
      house: values.House, // Дом
      organizationPhone: values.organizationPhone,
      organizationEmail: values.organizationEmail,
      building: values.Building, // Строение (если есть) - необязательно
      premises: values.Premises, // Помещение (если есть) - необязательно
      refOgd: values.refOgd,
      Checking_Account: values.Checking_Account, // Расчетный счет
      Bank_Identification_Code: values.Bank_Identification_Code, // Банковский идентификационный код
      Email: values.Email, // E-mail
      Phone: values.Phone, // Телефон
      Organization_Website: values.Organization_Website, // Веб-сайт организации
      Select_Role: values.Select_Role,
      User_Position: values.Phisical_position || values.User_Position,
      refBankCatalog: values.refBankCatalog,
      kbe: values.kbe,
      refProductGroup: values.refProductGroup,
    };


    const xmlData = generateXmlFromData(beckendData);


    if (!isUOT) {
      registrationRsaHead(xmlData)
    } else {
      registrationRsaUot(xmlData);
    }
  };

  // Страница с кнопкой Эцп
  if (!ecpKey) {
    return (
      <>
        {isLoading && (
          <Loader />
        )}
        {errorModal && (
          <ModalComponent
            isOpen={errorModal}
            onClose={() => {
              setErrorModal(null);
            }}
            headerContent={"Уведомление"}
            content={
              <p>
                {" "}
                {`Произошла ошибка: ${errorModal?.errorMessage ? errorModal?.errorMessage : "ошибка."}`}
              </p>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
        )}
        <div className="bg-bg flex items-center justify-center h-screen">
          <div className="flex flex-col justify-between h-[300px] bg-white p-8 rounded-lg shadow-lg w-96 mx-10">
            <a href="/" className="flex justify-center mb-4">
              <img
                src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
                alt="E-Track Logo"
                className="h-12"
              />
            </a>
            <div className="flex flex-col space-y-4">
              <button
                onClick={checkFIrstAuth}
                className="bg-green text-white font-semibold py-2 px-4 rounded"
              >
                Регистрация с помощью ЭЦП
              </button>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <a className="text-gray-600 text-sm"></a>
                <div className="relative">
                  <div>
                    <ChangingLanguage onlyDesktop={true} />
                  </div>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                    <i className="fas fa-chevron-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>

      <ModalComponent
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(null);
          setIsCheckedHead(false)
        }}
        headerContent={
          <div>
            <div className={style.modalHeader}>
              <div className={style.modalTitle}>Договор с оператором на товарную группу "Ювелирные изделия"</div>
              <div className={style.modalDesc}>Для подключения к информационной системе маркировки и прослеживания товаров необходимо подписать договоры электронно-цифровой подписью НУЦ РК</div>
            </div>
            <div className={style.HorLine}></div>
          </div>

        }
        content={
          <div className="flex flex-col gap-y-[20px]">
            <div className="flex w-full gap-x-[5px] items-center  text-black">
              <img src="assets/paperclip.svg" className="w-[20px]" />
              <div className={style.modalDesc}>Перед подписанием скачайте и ознакомьтесь с приложенными документами</div>
            </div>
            <div className={`flex flex-col gap-y-[10px] pl-[20px] text-black ${style.modalDesc}`} >
              <a href="#"
                className="custom-link text-blue-500 hover:text-blue-700"
                onClick={() => {
                  if(isOpenModal){
                    handleDownloadClick(isOpenModal[0]?.filename)
                  }
                }}
                >
                Типовой договор предоставления доступа к услугам Единого оператора маркировки и прослеживаемости товаров
              </a>
              <a href="#" 
              className="custom-link text-blue-500 hover:text-blue-700"
              onClick={() => {
                if(isOpenModal){
                  handleDownloadClick(isOpenModal[1]?.filename)
                }
              }}
              >
                Заявление о присоединении к типовому договору на подключение к информационной системе маркировки и прослеживаемости товаров
              </a>
            </div>
            <div className="flex gap-x-[5px] w-full items-center">
              <CheckBox
                id="myCheckbox"
                checked={isCheckedHead}
                onChange={handleChangeHead}
              />
              <label className={`max-w-full text-black ${style.modalDesc}`}>
                Согласен(на) с условиями договоров и заявлений
              </label>
            </div>

          </div>
        }
        customContent={
          <div className="flex gap-[20px]">
            <ModalButton
              color='green'
              type="button"
              disabled={!isCheckedHead}
              onClick={() => {
                registrationRsaHeadEnd()
              }}
            >
              Подписать и отправить
            </ModalButton>
            <ModalButton
              color="grey"
              type="button"
              onClick={() => {
                console.log(isOpenModal);
                setIsOpenModal(null);
                setIsCheckedHead(false)

              }}
            >
              Закрыть
            </ModalButton>
          </div>

        }
      />
      <ModalComponent
        isOpen={errorModal}
        onClose={() => {
          setErrorModal(null);
        }}
        headerContent={<h1 className={style.modal__header}>Уведомление</h1>}
        content={
          <p className={style.modal__text}>
            {`Произошла ошибка: ${errorModal?.errorMessage ? errorModal?.errorMessage : "ошибка."}`}
          </p>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
      <div className={`${style.mainWrapper} bg-bg h-screen`}>
        <Formik
          initialValues={initialValues}
          validationSchema={
            isUOT ? uotValidationSchema : defaultValidationSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form>
              <div
                className={`${style.formWrapper} flex flex-col items-center justify-center overflow-y-auto gap-4`}
              >
                <a href="/" className="flex justify-center mb-4">
                  <img
                    src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
                    alt="E-Track Logo"
                    className="h-12"
                  />
                </a>
                <div className={style.registrationTitle}>
                  <h2
                    className={`${style.titleTableMargin} ${style.orgTitleMain} m-0`}
                  >
                    Форма регистрации
                  </h2>

                  <div className={style.testButton}>
                    <ChangingLanguage onlyDesktop={true} />
                  </div>
                </div>
                {/* Блок об организации */}
                <div className={style.blockConfigRoot}>
                  <div className={style.titleWrapper}>
                    <p className="text-xs font-medium">
                      Сведения об организации
                    </p>
                  </div>
                  <div className={style.registrationConfigRoot}>
                    <div className={style.inputField}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          ИНН/БИН организации*
                        </label>
                        {formik.touched.IIN_BIN && formik.errors.IIN_BIN && (
                          <div className={style.errorLabel}>
                            {typeof formik.errors.organizationId === "string"
                              ? formik.errors.organizationId
                              : "Ошибка в поле организации"}
                          </div>
                        )}
                      </div>
                      <Field
                        type="text"
                        name="IIN_BIN"
                        placeholder="Введите ИИН организации"
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          Наименование организации*
                        </label>
                        {formik.touched.Organization_Name &&
                          formik.errors.Organization_Name && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.organizationId === "string"
                                ? formik.errors.organizationId
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="Organization_Name"
                        placeholder="Введите наименование организации"
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          ИИН руководителя*
                        </label>
                        {formik.touched.organizationId &&
                          formik.errors.organizationId && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.organizationId === "string"
                                ? formik.errors.organizationId
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="User_IIN"
                        placeholder="Введите ИИН руководителя"
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>ФИО*</label>
                        {formik.touched.organizationId &&
                          formik.errors.organizationId && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.organizationId === "string"
                                ? formik.errors.organizationId
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="User_fullName"
                        placeholder="Введите ФИО"
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>Должность*</label>
                        {formik.touched.organizationId &&
                          formik.errors.organizationId && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.organizationId === "string"
                                ? formik.errors.organizationId
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="User_Position"
                        placeholder="Должность"
                        className={style.placeholder}
                        disabled={true}
                      />
                    </div>


                    {!isUOT && (
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Товарная группа
                          </label>
                          {formik.touched.refProductGroup &&
                            formik.errors.refProductGroup && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.refProductGroup ===
                                  "string"
                                  ? formik.errors.refProductGroup
                                  : "Ошибка в поле организации"}
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="refProductGroup"
                          options={dataGroup}
                          placeholder="Выберите значение"
                          size="sm"
                          isMulti={false}
                          isDisabled={true}
                        />
                      </div>)}

                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          Телефон организации*
                        </label>
                        {formik.touched.organizationPhone &&
                          formik.errors.organizationPhone && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.organizationPhone ===
                                "string"
                                ? formik.errors.organizationPhone
                                : "Ошибка в поле организации"}
                            </div>
                          )}
                      </div>
                      <InputMask
                        mask="+7 (999) 999-99-99"
                        maskChar="_"
                        type="text"
                        name="organizationPhone"
                        placeholder="Введите телефон организации"
                        className={style.placeholder}
                        value={formik.values.organizationPhone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className={style.inputField1}>
                      <div className={style.labelAndButtonWrapper}>
                        <label className={style.inputLabel}>
                          E-mail организации*
                        </label>
                        {formik.touched.organizationEmail &&
                          formik.errors.organizationEmail && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.organizationEmail ===
                                "string"
                                ? formik.errors.organizationEmail
                                : "Ошибка в поле организации"}
                            </div>
                          )}
                      </div>
                      <Field
                        type="text"
                        name="organizationEmail"
                        placeholder="Введите Email организации"
                        className={style.placeholder}
                      />
                    </div>
                  </div>
                </div>
                {/* Блок об Адрессе */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">
                        Адресные данные организации
                      </p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Адрес населённого пункта*
                          </label>

                          {formik.touched.City && formik.errors.City && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.City === "string"
                                ? formik.errors.City
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="City"
                          placeholder="Выбрать город/населённый пункт"
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Улица*</label>
                          {formik.touched.Street && formik.errors.Street && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.Street === "string"
                                ? formik.errors.Street
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="Street"
                          placeholder="Выбрать улицу"
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Дом</label>
                          {formik.touched.House && formik.errors.House && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.House === "string"
                                ? formik.errors.House
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="House"
                          placeholder="Введите Дом"
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Строение</label>
                          {formik.touched.Building &&
                            formik.errors.Building && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.Building === "string"
                                  ? formik.errors.Building
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Building"
                          placeholder="Введите Строение"
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Помещение</label>
                          {formik.touched.Premises &&
                            formik.errors.Premises && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.Premises === "string"
                                  ? formik.errors.Premises
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Premises"
                          placeholder="Введите Помещение"
                          className={style.placeholder}
                        />
                      </div>
                      <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Код ОГД*
                          </label>
                          {formik.touched.refOgd &&
                            formik.errors.refOgd && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors
                                  .refOgd === "string"
                                  ? formik.errors.refOgd
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="refOgd"
                          options={dataOgd}
                          placeholder="Выберите значение"
                          size="sm"
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Блок об счете */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">Банковские данные</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Наименование банка*
                          </label>
                          {formik.touched.refBankCatalog &&
                            formik.errors.refBankCatalog && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors
                                  .refBankCatalog === "string"
                                  ? formik.errors.refBankCatalog
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="refBankCatalog"
                          options={dataNameBank}
                          placeholder="Выберите значение"
                          size="sm"
                          isMulti={false}
                        />
                      </div>
                      <div className={style.inputField}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Расчётный счёт*
                          </label>
                          {formik.touched.Checking_Account &&
                            formik.errors.Checking_Account && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.Checking_Account ===
                                  "string"
                                  ? formik.errors.Checking_Account
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Checking_Account"
                          placeholder="Расчётный счёт"
                          className={style.placeholder}
                        />
                      </div>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Банковский идентификационный код*
                          </label>
                          {formik.touched.Bank_Identification_Code &&
                            formik.errors.Bank_Identification_Code && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors
                                  .Bank_Identification_Code === "string"
                                  ? formik.errors.Bank_Identification_Code
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="Bank_Identification_Code"
                          options={bicData}
                          placeholder="Банковский идентификационный код"
                          size="sm"
                          isMulti={false}
                        />
                      </div>
                      <div
                        className={`${style.inputField} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            КБе*
                          </label>
                          {formik.touched.kbe &&
                            formik.errors.kbe && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors
                                  .kbe === "string"
                                  ? formik.errors.kbe
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="kbe"
                          placeholder="Введите КБе(2 символа)"
                          className={style.placeholder}
                          maxLength={2}
                        />
                      </div>

                    </div>
                  </div>
                )}

                {/* Блок об Юзере */}
                {!isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">Данные пользователя</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>E-mail*</label>
                          {formik.touched.Email && formik.errors.Email && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.Email === "string"
                                ? formik.errors.Email
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="Email"
                          placeholder="Введите E-mail"
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Телефон*</label>
                          {formik.touched.Phone && formik.errors.Phone && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.Phone === "string"
                                ? formik.errors.Phone
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <InputMask
                          mask="+7 (999) 999-99-99"
                          maskChar="_"
                          type="text"
                          name="Phone"
                          placeholder="Введите Телефон"
                          className={style.placeholder}
                          value={formik.values.Phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Веб-сайт организации
                          </label>
                          {formik.touched.Organization_Website &&
                            formik.errors.Organization_Website && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.Organization_Website ===
                                  "string"
                                  ? formik.errors.Organization_Website
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          type="text"
                          name="Organization_Website"
                          placeholder="Введите Веб-сайт организации"
                          className={style.placeholder}
                        />
                      </div>

                      <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Выбрать роль*
                          </label>
                          {formik.touched.Select_Role &&
                            formik.errors.Select_Role && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.Select_Role === "string"
                                  ? formik.errors.Select_Role
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="Select_Role"
                          options={authStartInfo.data.roles?.map(
                            (role: any) => ({
                              value: role.roleId,
                              label:
                                role[
                                `name${(i18n.language || "ru") === "kk" ? "Kk" : "Ru"}`
                                ] || role.nameRu,
                            }),
                          )}
                          placeholder="Выберите"
                          size="sm"
                          isMulti={true}
                          isDisabled={authStartInfo.data.roles?.length < 2}
                        />
                        {/* <Field
                      type="text"
                      name="Select_Role"
                      placeholder="Введите Роль"
                      className={style.placeholder}
                      disabled={true}
                    /> */}
                      </div>
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className={`${style.buttonForm} bg-bg text-gray-600 font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        Назад
                      </button>
                      <button
                        disabled={!isChecked}
                        type="submit"
                        className={`${style.buttonForm} buttonEtrackc bg-green text-white font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        Подписать
                      </button>
                    </div>
                    <div className={style.checkboxWraper}>
                      <label className={style.labelCheckBox}>
                        Даю согласие на обработку персональных данных
                      </label>
                      <CheckBox
                        id="myCheckbox"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

                {/* блок Физ лица */}

                {isUOT && (
                  <div className={style.blockConfigRoot}>
                    <div className={style.titleWrapper}>
                      <p className="text-xs font-medium">Данные пользователя</p>
                    </div>
                    <div className={style.registrationConfigRoot}>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Фамилия*</label>
                        </div>
                        <Field
                          type="text"
                          name="Phisical_name"
                          placeholder="Введите E-mail"
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Имя*</label>
                        </div>
                        <Field
                          type="text"
                          name="Phisical_surname"
                          placeholder="Введите E-mail"
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Отчество*</label>
                        </div>
                        <Field
                          type="text"
                          name="Phisical_otchestvo"
                          placeholder="Введите E-mail"
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>ИИН*</label>
                        </div>
                        <Field
                          type="text"
                          name="Phisical_Iin"
                          placeholder="Введите E-mail"
                          className={style.placeholder}
                        />
                      </div>
                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>E-mail*</label>
                          {formik.touched.Email && formik.errors.Email && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.Email === "string"
                                ? formik.errors.Email
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="Email"
                          placeholder="Введите E-mail"
                          className={style.placeholder}
                        />
                      </div>

                      <div className={style.inputField1}>
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>Телефон*</label>
                          {formik.touched.Phone && formik.errors.Phone && (
                            <div className={style.errorLabel}>
                              {typeof formik.errors.Phone === "string"
                                ? formik.errors.Phone
                                : "Ошибка в поле программы"}
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="Phone"
                          placeholder="Введите Телефон"
                          className={style.placeholder}
                        />
                      </div>

                      <div
                        className={`${style.inputField1} ${style.inputFieldNoOwerflow}`}
                      >
                        <div className={style.labelAndButtonWrapper}>
                          <label className={style.inputLabel}>
                            Выбрать роль*
                          </label>
                          {formik.touched.Select_Role &&
                            formik.errors.Select_Role && (
                              <div className={style.errorLabel}>
                                {typeof formik.errors.Select_Role === "string"
                                  ? formik.errors.Select_Role
                                  : "Ошибка в поле программы"}
                              </div>
                            )}
                        </div>
                        <Field
                          component={SelectCustomFormik}
                          name="Select_Role"
                          options={authStartInfo.data.roles?.map(
                            (role: any) => ({
                              value: role.roleId,
                              label:
                                role[
                                `name${(i18n.language || "ru") === "kk" ? "Kk" : "Ru"}`
                                ] || role.nameRu,
                            }),
                          )}
                          placeholder="Выберите"
                          size="sm"
                          isMulti={true}
                          isDisabled={authStartInfo.data.roles?.length < 2}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className={`${style.buttonForm} bg-bg text-gray-600 font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        Назад
                      </button>
                      <button
                        disabled={!isChecked}
                        type="submit"
                        className={`${style.buttonForm} buttonEtrackc bg-green text-white font-semibold py-2 px-4 h-10 self-end mb-2 rounded-[10px]`}
                      >
                        Подписать
                      </button>
                    </div>
                    <div className={style.checkboxWraper}>
                      <label className={style.labelCheckBox}>
                        Даю согласие на обработку персональных данных
                      </label>
                      <CheckBox
                        id="myCheckbox"
                        checked={isChecked}
                        onChange={handleChange}
                      />
                      <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
        {isLoading && (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Registration;
