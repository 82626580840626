import React, { FunctionComponent, PropsWithChildren, Suspense } from "react";
import Loader from "../Ui/Loader/Loader";

export const SuspenseLayout: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Suspense
      fallback={
        // TOOD: container
        <Loader/>
      }
    >
      {children}
    </Suspense>
  );
};
