import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyListPlaceholderProps {
  imageSrc?: string;
  title: React.ReactNode;
  description?: React.ReactNode;
}

export const EmptyListPlaceholderContent: React.FC<EmptyListPlaceholderProps> = ({
  imageSrc,
  title,
  description,
}) => {
  const emptyList = `/icons/empty-placeholder.svg`;

  return (
    <div className="col-span-12">
      <div className="flex items-center gap-3 justify-center flex-col placeholder">
        <img src={imageSrc || emptyList} alt="Empty List of a Collection" />
        <div className="flex items-center justify-center flex-col gap-2 placeholder__description">
          <div className="h__modal">{title}</div>
          <div className="p__modal">{description}</div>
        </div>
      </div>
    </div>
  );
};
