import React from "react";
import { ChangingLanguage } from "../../shared/components/ChangingLanguage";
import { useTranslation } from "react-i18next";
import { normalizedLocale } from "../../common/libs/normalizedLocale";
import { Link } from "react-router-dom";

const Authorization = () => {
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  return (
    <div className="bg-bg flex items-center justify-center h-screen">
      <div className="flex flex-col justify-between h-[300px] bg-white p-8 rounded-lg shadow-lg w-96 mx-10">
        <a href="/" className="flex justify-center mb-4">
          <img
            src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
            alt="E-Track Logo"
            className="h-12"
          />
        </a>
        <div className="flex flex-col space-y-4">
          <Link
            to="/sign-in"
            className="text-center bg-green text-white font-semibold py-2 px-4 rounded"
          >
            {t("authorization.enter")}
          </Link>

          <Link
            to="/registration"
            className="text-center bg-bg text-gray-600 font-semibold py-2 px-4 rounded"
          >
            {t("authorization.sign-in")}
          </Link>
          <div className="flex items-center justify-end">
            {/* <a href="#" className="text-gray-600 text-sm">
              {t("authorization.forgetPasword")}
            </a> */}
            <div className="relative">
              <div>
                <ChangingLanguage onlyDesktop={true} />
              </div>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
