import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DocumentsService, {
  viewAndPrintDocument,
} from "../../../common/api/documentsApi";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import { EmptyListPlaceholderContent } from "../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import Stepper from "../../../shared/components/Stepper/Stepper";
import Pagination from "../../../shared/components/Table/Pagination";
import { ITable } from "../../../shared/components/Table/types";
import { useAppSelector } from "../../../shared/hook/useAppSelector";
import { CheckResult } from "../Upload/CheckResults/CheckResult";
import { DocumentUpload } from "../Upload/DocumentUpload/DocumentUpload";
import { DocumentsSearch } from "../Upload/DocumentsSearch/DocumentsSearch";
import { MarkScan } from "../Upload/MarkScan/MarkScan";
import style from "./DocumentInventoryResumption.module.css";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import {
  getDocumentXMLForSigning,
  saveSignedDocumentXML,
} from "../../../common/api/documentSignature";
import { createDocument } from "../../../common/api/notificationApi";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import Loader from "../../../shared/Ui/Loader/Loader";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import { useAppDispatch } from "../../../shared/hook";
import { deleteDataDocument } from "./../../../shared/slice/documentState";
import { submitXmlProduct } from "../../../shared/signature/documentsSignature";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";

function DocumentInventoryResumptionOrders() {
  const dispatch = useAppDispatch();
  const documentState = useAppSelector((state) => state.documentState);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const navigate = useNavigate();
  const { inventoryResumptionDocumentId } = useParams();
  const [tableData, setTableData] = useState<ITable>();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [errorModal, setErrorModal] = useState<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(
    null,
  );
  const [isDocumentSearchModalOpen, setIsDocumentSearchModalOpen] =
    useState<boolean>(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const [isMarkScanModal, setIsMarkScanModal] = useState<boolean>(false);
  const [isCheckOpen, setIsCheckOpen] = useState<any>(null);
  const [status, setStatus] = useState<any>("new");
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [toggleOnLoad, setToggleOnLoad] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteMarks, setDeleteMarks] = useState<number[]>([]);
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const userData = useAppSelector((state) => state.auth.userData);
  const [initialValues, setInitialValues] = useState<any>({
    id: null, // ИД
    refDocType: 3,

    refPutReason: null, //Причина вывода из оборота
    foundationDocumentName: null,
    foundationDocumentNumber: null,
    foundationDocumentDate: null,
    note: "" || null, // Примечание

    orgId: "",
    createUser: "",
    createdAt: "",
    // updatedAt: "",
    // signingAt: "",
    receiver: "",
    sender: "",
    status: "new",
  });

  useEffect(() => {
    const roleObj = () => {
      return {
        roleDoc: roleMatrix?.roleMatrix?.find(item => item.id === 4).specificAction.documents[0],
        roleDraft: roleMatrix?.roleMatrix?.find(item => item.id === 6)
      }
    }

    setRole(roleObj)

    onLoad();
    setIsLoading(false)
    if (documentState.isSafe) {
      setIsFormChanged(true);
    }
  }, [
    pagination,
    isDocumentSearchModalOpen,
    isMarkScanModal,
    isCheckOpen,
    CheckResult,
    navigate,
    toggleOnLoad,
  ]);

  async function onLoad() {
    if (inventoryResumptionDocumentId !== undefined) {
      await DocumentsService.getDocMarksList(
        inventoryResumptionDocumentId,
        pagination?.pageIndex + 1,
        pagination.pageSize,
      )
        .then((response) => {
          if (response) {
            setTableData({
              values: response?.products,
              currentPage: response?.currentPage,
              itemsPerPage: response?.itemsPerPage,
              totalItems: response?.totalItems,
              totalPages: response?.totalPages,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching marks from document:", error),
            setTableData({
              values: [],
              currentPage: 0,
              itemsPerPage: 0,
              totalItems: 0,
              totalPages: 0,
            });
        });
      await DocumentsService.getDocument(inventoryResumptionDocumentId)
        .then((documentResponse) => {
          const { type, label } = getStatusType(documentResponse.data.status);
          setStatusType({ type, label });
          setInitialValues(documentResponse.data);
          setStatus(documentResponse.data.status);
        })
        .catch((error) => {
          navigate("/404");
        });
    }
  }

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: t("documentStatus.error") };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  async function submitCreate() {
    const backendData = {
      ...documentState.documentData,
      refDocType: 3,
      id: inventoryResumptionDocumentId || null,
    };
    if (inventoryResumptionDocumentId && documentState.isSafe) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message:error?.response?.data?.message
            ? error?.response?.data?.message
            :  t("documentNotification.document_edit_error"),
          });
        });
    }
    if (!inventoryResumptionDocumentId) {
      const create = await createDocument(backendData)
        .then((response) => {
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          navigate(
            `/documents/inventory-resumption/${response.data.document.id}/orders`,
          );
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message:error?.response?.data?.message
            ? error?.response?.data?.message
            :  t("documentNotification.document_save_error"),
          });
        });
    }
  }

  const getDocument = async () => {
    try {
      if (inventoryResumptionDocumentId) {
        setIsLoading(true);
        const fileBlob = await DocumentsService.downloadDocumentMarks(inventoryResumptionDocumentId);
        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${initialValues?.docNumber}.xlsx`);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Это ЗАПРОС НА ФАЙЫ ДОКА!
  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const fileBlob = await viewAndPrintDocument(
        inventoryResumptionDocumentId,
      );
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `document_${inventoryResumptionDocumentId}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Это ЗАПРОС НА ПОДПИСАНИЕ ЗАЯВКИ!
  // async function submitXmlProduct() {
  //   const ncalayerClient = new NCALayerClient();

  //   try {
  //     await ncalayerClient.connect();
  //   } catch (error) {
  //     setAlertOpen({
  //       status: "error",
  //       message: t("documentNotification.nca_no_connect"),
  //     });
  //     return;
  //   }

  //   let xml;
  //   try {
  //     setIsLoading(true);
  //     xml = await getDocumentXMLForSigning(inventoryResumptionDocumentId);
  //     console.log("xml", xml);
  //     if (!xml || !xml.data) {
  //       console.log("Не удалось получить XML документ для подписи.");
  //       return;
  //     }
  //     if (xml.data.errorFields && xml.data.errorFields?.length > 0) {
  //       setErrorModal(xml.data.errorFields);
  //       console.log("xml1", xml.data.errorFields);
  //       return;
  //     }
  //     if (xml.data.problemMarks && xml.data.problemMarks?.length > 0) {
  //       setIsCheckOpen(xml.data.problemMarks);
  //       console.log("xml2", xml.data.problemMarks);
  //       return;
  //     }
  //   } catch (error: any) {
  //     setErrorModal({
  //       singleError: true,
  //       errorMessage: error.response?.data?.message
  //         ? error.response?.data?.message
  //         : t("documentNotification.unknown_error"),
  //     });
  //     return;
  //   } finally {
  //     setIsLoading(false);
  //   }
  //   let xmlSignature;
  //   try {
  //     xmlSignature = await ncalayerClient.basicsSignXML(
  //       NCALayerClient.basicsStoragesAll,
  //       xml.data.xml,
  //       NCALayerClient.basicsXMLParams,
  //       process.env.REACT_APP_ETRACK_TEST
  //         ? NCALayerClient.basicsSignerTestAny
  //         : NCALayerClient.basicsSignerSignAny,
  //       "ru",
  //     );
  //     console.log("Подпись XML:", xmlSignature[0]);
  //   } catch (error: any) {
  //     setErrorModal(error.data.message);
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const documentIdValue = inventoryResumptionDocumentId ?? "0";
  //     await saveSignedDocumentXML(documentIdValue, xmlSignature[0]);
  //     alert(t("documentNotification.document_xml_success"));
  //     navigate("/documents/inbox");
  //   } catch (error) {
  //     const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
  //     console.log("e", e);
  //     const errorMessage = e.response?.data;
  //     alert(t("documentNotification.document_xml_error"));
  //     console.log("errorka", errorMessage);
  //     setErrorModal({
  //       singleError: true,
  //       errorMessage: errorMessage?.message
  //         ? errorMessage?.message
  //         : t("documentNotification.unknown_error"),
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  function generateErrorMessages(errorFields: any) {
    const errorMessages = errorFields.map((field: any) => {
      switch (field) {
        default:
          return `Ошибка в поле ${field}`;
      }
    });

    return errorMessages;
  }
  function hasErrorFields(errorModal: any) {
    return (
      errorModal && errorModal.errorFields && errorModal.errorFields?.length > 0
    );
  }

  const checkboxDelete = (id: any) => {
    setDeleteMarks((prevDeleteMarks: any) => {
      if (prevDeleteMarks.includes(id)) {
        return prevDeleteMarks.filter((item: any) => item !== id);
      } else {
        return [...prevDeleteMarks, id];
      }
    });
  };

  const fullDeleteMarks = () => {
    setDeleteMarks([]);
    if (tableData?.values) {
      const allIds = tableData.values.map((value: any) => value?.markId);
      setDeleteMarks(allIds);
    }
    if (deleteMarks?.length === tableData?.values?.length) {
      setDeleteMarks([]);
    }
  };

  const handleDelete = (docId?: string, marks?: string[]) => {
    if (typeof docId !== undefined && marks) {
      DocumentsService.deleteMarks(docId, marks)
        .then((response) => {
          if (tableData?.currentPage === tableData?.totalPages) {
            setPagination((prevState) => ({
              ...prevState,
              pageIndex:
                prevState.pageIndex === 0
                  ? prevState.pageIndex
                  : prevState.pageIndex - 1,
            }));
          }
          setDeleteMarks([]);
        })
        .catch((error) =>
          console.log(`Error deleting mark from document:" ${error}`),
        )
        .finally(() => {
          setIsDeleteModalOpen(null);
          onLoad();
        });
    } else {
      setErrorModal(t("documentNotification.no_id_error"));
      setIsDeleteModalOpen(null);
    }
  };

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      default:
        return t("documentType.unknown");
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormChanged !== false &&
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("inventory-resumption"),
  );

  if (isLoading && !role) return <Loader/>;

  if (!role || !role?.roleDraft?.view) {
    return (
      <PageNotRole />
    )
  }

  return (
    <div className="max-w-[1585px] w-full m-t-[20px]">
      {blocker && blocker.state === "blocked" ? (
        <ModalComponent
          isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
          onClose={() => {
            if (blocker && typeof blocker.reset === "function") {
              blocker.reset();
            } else {
              console.error("Reset function is not available.");
            }
          }}
          headerContent={"Уведомление"}
          content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
          customContent={
            <>
              <ModalButton
                type="button"
                color="green"
                onClick={() => {
                  if (blocker && typeof blocker.proceed === "function") {
                    blocker.proceed();
                  } else {
                    console.error("Proceed function is not available.");
                  }
                }}
              >
                {t("mainButtons.continue")}
              </ModalButton>
              <ModalButton
                type="button"
                onClick={() => {
                  console.log(initialValues);
                  if (blocker && typeof blocker.reset === "function") {
                    blocker.reset();
                  } else {
                    console.error("Reset function is not available.");
                  }
                }}
              >
                {t("mainButtons.cancel")}
              </ModalButton>
            </>
          }
        />
      ) : null}
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
          <div>
            {inventoryResumptionDocumentId ? (
              <div className="flex gap-[20px]">
                <div className="flex flex-col">
                  <StatusBadge type={statusType.type}>
                    {t("documentLabels.status") + ": " + statusType.label}
                  </StatusBadge>
                  {(initialValues?.signingAt || initialValues?.createdAt) && (
                    <div className="text-[14px] font-semibold text-gray-700 ml-[9px]">
                      {format(
                        new Date(
                          initialValues?.signingAt || initialValues?.createdAt,
                        ),
                        "dd.MM.yyyy, HH:mm",
                      )}
                    </div>
                  )}
                </div>
                <span className="flex gap-[10px]">
                  <div>{getDescription(initialValues.refDocType)}:</div>
                  <div>
                    {initialValues.docNumber
                      ? initialValues.docNumber
                      : " " + t("mainLabels.not_generated")}
                  </div>
                </span>
              </div>
            ) : (
              <div className="flex gap-[20px]">
                <StatusBadge type={statusType.type}>
                  {t("documentLabels.status") + " " + statusType.label}
                </StatusBadge>
                <div>
                  {t("documentLabels.create_document")}
                  {": "}
                  {t("documentType.re_introduction_notification")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-between ">
          <div className="my-auto">
            <Stepper currentStep={2} />
          </div>

          <div className="flex flex-row gap-[20px] flex-wrap">
            {inventoryResumptionDocumentId && status === "PROCESSED" && 
            tableData?.totalItems &&
            tableData?.totalItems > 0 &&
            role.roleDoc.actions[1].active ? (
              <button
                className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                type="button"
                onClick={() => getDocument()}
              >
                {t("documentLabels_3.download_marks")}
              </button>
            ) : null}
            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => {
                dispatch(deleteDataDocument());
                  if (initialValues.status == "DRAFT") {
                    console.log("Navigating to drafts");
                    navigate("/documents/drafts");
                }else if (initialValues.status == "new") {
                  console.log("Navigating to sent");
                  navigate("/documents/inbox");
                } 
                else if (userData.iinBin !== initialValues.receiver) {
                    console.log("Navigating to sent");
                    navigate("/documents/sent");
                } else {
                    console.log("Navigating to inbox");
                    navigate("/documents/inbox");
                }
                
              }}
            >
              {t("mainButtons.close")}
            </button>
            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
              type="button"
              onClick={() =>
                inventoryResumptionDocumentId
                  ? navigate(
                      `/documents/inventory-resumption/${inventoryResumptionDocumentId}`,
                    )
                  : navigate(`/documents/inventory-resumption/new`)
              }
            >
              {t("mainButtons.back")}
            </button>
            {((status === "new" && role.roleDraft.edit) ||
                  (status == "DRAFT" && role.roleDraft.edit)) && (
              <div className="flex flex-row gap-[20px] flex-wrap">
                <button
                  className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={() => submitCreate()}
                >
                  {t("mainButtons.save")}
                </button>
              </div>
            )}
            {(status == "DRAFT" && role.roleDraft.specificAction.actions[0].active) && (
              <div className="flex flex-row gap-[20px] flex-wrap">
                <button
                  className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                  type="button"
                  onClick={() =>
                    submitXmlProduct(
                      inventoryResumptionDocumentId,
                      navigate,
                      setAlertOpen,
                      setIsLoading,
                      setErrorModal,
                      setIsCheckOpen,
                      t,
                    )
                  }
                  // disabled={tableData?.values?.length === 0 || formik.values.status !== "new" && formik.values.status !== "DRAFT"}
                >
                  {t("mainButtons.sign")}
                </button>
              </div>
            )}
            {status !== "DRAFT" && status !== "new" && role.roleDoc.actions[2].active && (
              <div className="flex flex-row gap-[20px] flex-wrap">
                <button
                  className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                  type="button"
                  onClick={() => handleDownloadClick()}
                  // disabled={tableData?.values?.length === 0 || formik.values.status !== "new" && formik.values.status !== "DRAFT"}
                >
                  {t("mainButtons.print")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px]">
          <div className=" my-5 relative flex flex-row align-center gap-x-[20px]">
            {((status === "new" && role.roleDraft.edit) ||
                  (status == "DRAFT" && role.roleDraft.edit)) && (
              <>
                <button
                  onClick={() => {
                    dispatch(deleteDataDocument());
                    submitCreate();
                  }}
                  className=" bg-green text-white font-semibold py-2 px-4 rounded"
                  data-tooltip-offset={4}
                  data-tooltip-id="document-type"
                >
                  {t("documents_orders.add_products")}
                </button>
                {deleteMarks?.length > 0 && (
                  <button
                    onClick={() => setIsDeleteModalOpen(deleteMarks)}
                    className=" bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                  >
                    {t("documents_orders.delete_products")}
                  </button>
                )}
                <div className="z-[1000] relative	">
                  <Tooltip
                    id="document-type"
                    className={classNames(
                      style.wrapperTooltip,
                      "drop-shadow-2xl",
                    )}
                    openOnClick={true}
                    clickable={true}
                    noArrow={true}
                    opacity={1}
                    place={"bottom-end"}
                  >
                    <div
                      className={classNames(
                        style.tooltip,
                        "flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden",
                      )}
                    >
                      <button
                        type="button"
                        className={classNames(
                          "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                        )}
                        onClick={() => setIsFileUploadModalOpen(true)}
                      >
                        <span>{t("documents_orders.upload_from_file")}</span>
                      </button>
                      <button
                        className={classNames(
                          "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                        )}
                        onClick={() => setIsDocumentSearchModalOpen(true)}
                      >
                        <span>{t("documents_orders.add_codes")}</span>
                      </button>
                      <button
                        className={classNames(
                          "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                        )}
                        onClick={() => setIsMarkScanModal(true)}
                      >
                        <span>{t("mark_scan.scan_codes")}</span>
                      </button>
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
            {/* <div className="flex flex-row gap-[10px]">
              <button
                onClick={() =>
                  setPagination((prevValues: PaginationState) => {
                    return { ...prevValues, pageIndex: 0 };
                  })
                }
              >
                <img src="/icons/update.svg"></img>
              </button>
            </div> */}
          </div>
          <div className="w-full">
            <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                  {(status === "new" ||
                      (status === "DRAFT" && tableData?.totalItems !== 0)) && (
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        <CheckBox
                          checked={
                            deleteMarks?.length === tableData?.values?.length
                          }
                          onChange={() => {
                            fullDeleteMarks();
                          }}
                        />
                      </th>
                    )}
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      №{t("documents_orders.number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.ntin")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.order_number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.product_name")}
                    </th>
                    {/* <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Наименование изготовителя
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Тип упаковки
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Артикул
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Тип изделия
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Наименование драгоценного металла
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Проба
                    </th> */}
                  </tr>
                </thead>
                {tableData?.values?.length === 0 || !tableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_orders.no_data_title")}
                            description={t(
                              "documents_orders.no_data_description",
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {tableData?.values?.map((value: any, index: any) => (
                      <tr key={`tr-${index}-${value.id}`}>
                        {((status === "new" && role.roleDraft.edit) ||
                  (status == "DRAFT" && role.roleDraft.edit)) && (
                          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                            <CheckBox
                              id="myCheckbox"
                              checked={deleteMarks.includes(value?.markId)}
                              onChange={() => checkboxDelete(value?.markId)}
                            />
                          </td>
                        )}
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {index + 1 || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.markCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productTypeCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.orderNumber || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productName || "-"}
                        </td>
                        {((status === "new" && role.roleDraft.edit) ||
                  (status == "DRAFT" && role.roleDraft.edit)) && (
                          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                            <img
                              src="/icons/trash.svg"
                              className="cursor-pointer min-w-[20px]"
                              onClick={() =>
                                setIsDeleteModalOpen([value?.markId])
                              }
                            ></img>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <Pagination
              totalItems={tableData?.totalItems || 0}
              totalPages={tableData?.totalPages || 0}
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
          <ModalComponent
            isOpen={!!isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <p className="p__modal">{t("documents_orders.confirm_delete")}</p>
            }
            customContent={
              <div className="flex flex-row gap-[10px]">
                <ModalButton
                  type="button"
                  color="grey"
                  onClick={() => {
                    setIsDeleteModalOpen(null);
                  }}
                >
                  {t("mainButtons.cancel")}
                </ModalButton>
                <ModalButton
                  // color="green"
                  type="button"
                  onClick={() => {
                    isDeleteModalOpen &&
                      handleDelete(
                        inventoryResumptionDocumentId,
                        isDeleteModalOpen,
                      );
                    setIsDeleteModalOpen(null);
                    onLoad();
                  }}
                >
                  {t("mainButtons.delete")}
                </ModalButton>
              </div>
            }
          />
          <ModalComponent
            isOpen={errorModal && !errorModal.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")} -{" "}
                {t("documentNotification.check_fields")}:
              </h1>
            }
            content={
              <div className="error-list">
                <ul>
                  {errorModal && errorModal?.length > 0 ? (
                    errorModal.map((error: any, index: any) => (
                      <li className="border-b border-gray-300 py-1" key={index}>
                        {t(`error_dictionary.${error}`)}
                      </li>
                    ))
                  ) : (
                    <li>{t("documentNotification.no_errors")}</li>
                  )}
                </ul>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
          <ModalComponent
            isOpen={errorModal?.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {" "}
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <div className="error-list">
                <p className="border-b border-gray-300 py-1">
                  {t("documentStatus.error")}: {errorModal?.errorMessage}
                </p>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />

          <DocumentsSearch
            isOpen={isDocumentSearchModalOpen}
            setIsOpen={setIsDocumentSearchModalOpen}
            documentId={inventoryResumptionDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <DocumentUpload
            isOpen={isFileUploadModalOpen}
            setIsOpen={setIsFileUploadModalOpen}
            documentId={inventoryResumptionDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <MarkScan
            isOpen={isMarkScanModal}
            setIsOpen={setIsMarkScanModal}
            documentId={inventoryResumptionDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <CheckResult
            isOpen={isCheckOpen}
            setIsOpen={setIsCheckOpen}
            problemMarks={isCheckOpen}
            docId={inventoryResumptionDocumentId}
          />

          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default DocumentInventoryResumptionOrders;
