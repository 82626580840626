export function generateXmlFromData(data: any) {
  const fields = [
    { name: "IIN_BIN", xmlTag: "iinBin" },
    { name: "User_IIN", xmlTag: "userIIN" },
    { name: "User_Name", xmlTag: "userName", optional: true },
    { name: "User_Surname", xmlTag: "userSurname", optional: true },
    { name: "User_Patronymic", xmlTag: "userPatronymic", optional: true },
    { name: "User_Position", xmlTag: "userPosition" },
    { name: "Organization_Name", xmlTag: "organizationName" },
    { name: "Organization_Website", xmlTag: "organizationWebsite" },
    { name: "organizationPhone", xmlTag: "organizationPhone" },
    { name: "organizationEmail", xmlTag: "organizationEmail" },
    { name: "Phone", xmlTag: "phone", optional: true },
    { name: "Email", xmlTag: "email", optional: true },
    { name: "Bank_Identification_Code", xmlTag: "bankIdentificationCode" },
    { name: "Checking_Account", xmlTag: "checkingAccount" },
    { name: "refBankCatalog", xmlTag: "refBankCatalog" },
    { name: "kbe", xmlTag: "kbe" },
    { name: "refProductGroup", xmlTag: "refProductGroup" },
    // Обрабатываем Select_Role отдельно
    // { name: "Select_Role", xmlTag: "selectRole", nestedTag: "roleId" },
    // Адрес обрабатывается отдельно, так как вложенный объект
  ];

  let xml = "<registerInfo>";

  fields.forEach(({ name, xmlTag, optional }) => {
    const value = data[name];
    if (value || !optional) {
      xml += `<${xmlTag}>`;
      xml += `${value}`;
      xml += `</${xmlTag}>`;
    } else {
      xml += `<${xmlTag}/>`;
    }
  });

  // Обрабатываем Select_Role отдельно
  if (data.Select_Role && data.Select_Role.length) {
    data.Select_Role.forEach((roleId: any) => {
      xml += `<selectRole><roleId>${roleId}</roleId></selectRole>`;
    });
  }

  // Добавляем адрес как вложенный объект
  xml += "<address>";
  ["city", "street", "house", "building", "premises", "refOgd"].forEach((field) => {
    const value = data[field];
    if (value) {
      xml += `<${field}>${value}</${field}>`;
    } else {
      xml += `<${field}/>`;
    }
  });
  xml += "</address>";
  xml += "</registerInfo>";

  return xml;
}
