import { t } from "i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import MockSkeletonProducts from "./utils/MockSkeletonProducts";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PaginationState } from "@tanstack/react-table";
import { useAppSelector } from "../../shared/hook";
import Pagination from "../../shared/components/Table/Pagination";
import { useTranslation } from "react-i18next";
import { table } from "console";
import { getProductCardByNTIN } from "../../common/api/chequesApi";
import { normalizedLocale } from "../../common/libs/normalizedLocale";

function MyCards() {
  const [errorModal, setErrorModal] = useState(false);
  const navigate = useNavigate();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const { t, i18n } = useTranslation();
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [initialValues , setInitialValues] = useState<any>(null)
  const { ntin } = useParams();
  const locale = normalizedLocale(i18n.language);

  console.log(locale,'locale');
  

  useEffect(() => {
    console.log(ntin,'123123');
    onLoad();
  }, [pagination, location]);
console.log(initialValues);

  async function onLoad() {
    if (!ntin) return;

    try {
      const orderResponse = await getProductCardByNTIN(ntin);
      console.log(orderResponse.data, 'orderResponse');

      setInitialValues(orderResponse.data);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    }
  }
  console.log(initialValues);
  const tnvedCode = initialValues?.tnved.length > 0 ? initialValues?.tnved[0].tnvedCode : '';
  const tnvedLabel = initialValues?.tnved.length > 0 ? initialValues?.tnved[0].tnvedLabel : '';
  const kpvedCode = initialValues?.kpved.length > 0 ? initialValues?.kpved[0].kpvedCode : '';
  const kpvedLabel = initialValues?.kpved.length > 0 ? initialValues?.kpved[0].kpvedLabel : '';
  const imageUrl = 
  initialValues?.image && initialValues.image.length > 0
  ? initialValues.image[0].imageUrl
  : 
  './assets/ring.png';


  const calculateItemNumber = (index: any) => {
    return pagination.pageIndex * pagination.pageSize + index + 1;
  };

  const renderActions = (
    value: any,
    setErrorModal: any,
    navigate: any,
    setAlertOpen?: any,
    setIsCheckOpen?: any,
  ) => {
    const actions = [];
    actions.push({
      title: t("orderList.show"),
      action: () => {
        if (value.refDocType == "1") {
          navigate(`/documents/import-other-countries/${value?.id}`);
        } else if (value.refDocType == "2") {
          navigate(`/documents/inventory-write-off/${value?.id}`);
        } else if (value.refDocType == "3") {
          navigate(`/documents/inventory-resumption/${value?.id}`);
        } else if (value.refDocType == "4") {
          navigate(`/documents/import-eaeu/${value?.id}`);
        } else if (value.refDocType == "5") {
          navigate(`/documents/transfer-acceptance/${value?.id}`);
        }
      },
    });
    return actions;
  };

  return (
    <div className="w-full m-[50px] max-w-[1920px] flex flex-col gap-[20px] font-['Inter']">
      <div className="flex flex-col min-w-0">
        <div className="mt-[20px] relative rounded-xl flex flex-row align-center justify-between">
          <h2 className="my-3 text-xl font-semibold">
          {t("productCard.title")}: 
          {/* {initialValues?.nameRu} */}
          {locale === 'ru' ? initialValues?.nameRu : initialValues?.nameKz }
          </h2>
          <button
            className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
            type="button"
            onClick={() => navigate("/products-catalog/my-cards")}
          >
            {t("mainButtons.close")}
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px]">
        <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
          <img src="/assets/docs.png" className="w-[20px]" />
          <div className="uppercase"> {t("productCard.title")}</div>
        </div>

        <div className="flex gap-x-[20px] gap-y-[20px] text-base flex-wrap justify-between after:block after:w-full after:h-[1px] border-b-[2px] pb-[20px]">
          {/* <div className="flex w-full max-w-[calc(67%-20px)] justify-between gap-[20px] items-start text-base flex-wrap">
    <div className="w-full min-w-[200px] max-w-[calc(50%-10px)] text-gray-600 font-semibold">
    Полное наименование ЮИ RU
    <p className="font-light">
    Кольцо золотое без камня
    </p>
    </div>
    <div className="w-full min-w-[200px] max-w-[calc(50%-10px)] text-gray-600 font-semibold">
    Краткое наименование ЮИ RU
    <p className=" font-light">Кольцо 11</p>
    </div>
    <div className="w-full min-w-[200px] max-w-[calc(50%-10px)] text-gray-600 font-semibold">
    Дата и время публикации
    <p className="text-gray-600 font-light">12.03.2024 12:34:00</p>
    </div>
    <div className="w-full min-w-[200px] max-w-[calc(50%-10px)] text-gray-600 font-semibold">
    ИИН/БИН создателя карточки ЮИ
    <p className="text-gray-600 font-light">2304400566676</p>
    </div>
    </div> */}
          <div className="flex flex-col gap-[30px] justify-between flex-wrap max-w-[calc(67%-20px)] w-full ">
            <div className="flex flex-row justify-between gap-[20px] flex-wrap ">
              <div className="w-full max-w-[calc(50%-10px)] flex-wrap min-w-[160px]">
              {t("productCard.fullName")}
                <p className="font-light">
                  {/* {initialValues?.nameRu} */}
                  {locale === 'ru' ? initialValues?.nameRu : initialValues?.nameKz }
                </p>
              </div>
              <div className="w-full max-w-[calc(50%-10px)] flex-wrap min-w-[160px]">
              {t("productCard.publicationDateTime")}
                <p className="font-light">{initialValues?.createdAt}</p>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-[20px] flex-wrap w-full ">
              <div className="w-full max-w-[calc(50%-10px)] min-w-[160px]">
              {t("productCard.shortName")}
                <p className="font-light">
                  {/* {initialValues?.shortNameRu} */}
                  {locale === 'ru' ? initialValues?.shortNameRu : initialValues?.shortNameKz }
                  </p>
              </div>
              <div className="w-full max-w-[calc(50%-10px)] min-w-[160px]">
              {t("productCard.creatorIIN")}
                <p className="font-light">{initialValues?.applicant}</p>
              </div>
            </div>
          </div>
          <div className="text-gray-600 justify-center  flex-1 w-full min-w-[200px] max-w-[calc(33%-10px)] h-[200px] flex flex-wrap">
            <div className="text-gray-600  rounded-[10px] w-[200px] h-[200px] font-semibold bg-black">
              <img src={imageUrl} alt=""  className="w-full h-full"/>
            </div>
          </div>
        </div>
        <div className="rounded-md">
          <div className="flex flex-col gap-[20px] ">
            <div
              className=" flex items-center text-base text-gray-600 font-light gap-x-[10px] cursor-pointer pb-[20px] border-b-[2px] "
              onClick={() => setIsOpen1(!isOpen1)}
            >
              <img src={"/assets/docs.png"} className="w-[20px]" alt="icon" />
              <div className="uppercase ">{t("productCard.productDescription")}</div>
              <div
                className={`ml-auto transform transition-transform duration-300 ${isOpen1 ? "rotate-180" : "rotate-0"}`}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isOpen1 ? "max-h-screen" : "max-h-0"} `}
            >
              <div className="flex flex-wrap gap-[20px] ">
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.productType")}
                    <p className="font-light">
                      {/* {initialValues?.nameRu} */}
                      {locale === 'ru' ? initialValues?.nameRu : initialValues?.nameKz }
                      </p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.ntin")}
                    <p className="font-light">{initialValues?.ntin}</p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.tnvedCode")}
                    <p className="font-light">{tnvedCode}</p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.tnvedDescription")}
                    <p className="font-light">
                      {tnvedLabel}
                    </p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.kpvedCode")}
                    <p className="font-light">{kpvedCode}</p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.kpvedDescription")}
                    <p className="font-light">
                    {kpvedLabel}
                    </p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.countryOfOrigin")}
                    <p className="font-light">{initialValues?.countryCode}</p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.manufacturerNameRU")}
                    <p className="font-light">
                      {initialValues?.producerRu}
                      {locale === 'ru' ? initialValues?.producerRu : initialValues?.producerKz }
                      </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex items-center text-base text-gray-600 font-light gap-x-[10px] cursor-pointer pb-[20px] border-b-[2px]"
              onClick={() => setIsOpen2(!isOpen2)}
            >
              <img src="/assets/docs.png" className="w-[20px]" alt="icon" />
              <div className="uppercase">{t("productCard.productCharacteristics")}</div>
              <div
                className={`ml-auto transform transition-transform duration-300 ${isOpen2 ? "rotate-180" : "rotate-0"}`}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isOpen2 ? "max-h-screen" : "max-h-0"}`}
            >
              <div className="flex flex-wrap gap-[20px] ">
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.mainMaterial")}
                    <p className="font-light">{initialValues?.metal} </p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.sampleCompliance")}
                    <p className="font-light">{initialValues?.purity}</p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.coatingRU")}
                    <p className="font-light">
                      {/* {initialValues?.coatingRu} */}
                      {locale === 'ru' ? initialValues?.coatingRu : initialValues?.coatingKz }
                      </p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.article")}
                    <p className="font-light">{initialValues?.article}</p>
                  </div>
                </div>
                <div className="w-full min-w-[200px] max-w-[calc(33%-10px)]">
                  <div className="text-gray-600 font-semibold">
                  {t("productCard.trademark")}
                    <p className="font-light">{initialValues?.brand}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full items-center text-base text-gray-600 font-light gap-x-[10px]">
              <img src="/assets/docs.png" className="w-[20px]" />
              <div className="uppercase">{t("productCard.inserts")}</div>
            </div>
            <table className="max-w-full w-full leading-normal">
              <thead className="border-b-2 border-gray-300">
                <tr>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("productCard.insertMaterial")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("productCard.insertCount")}
                  </th>
                  <th
                    className="px-5 py-3 text-center items-center text-gray-600 font-semibold bg-white border-b border-gray-200 flex justify-center"
                    scope="col"
                  >
                   {t("productCard.weight")}
                  </th>
                </tr>
              </thead>
              <tbody className="font-normal text-center">
                {initialValues?.insertion.map((value: any, index: any) => (
                  <tr>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                      {value.insertionMaterial}
                    </td>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                    {value.insertionCount}
                    </td>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                    {value.insertionWeight}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="h-[10px] py-5"></div>
    </div>
  );
}

export default MyCards;
