import React, { useEffect } from "react";
import styles from "./PageNotFound.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export interface IPageErrorMessage {
  message?: string;
}

const PageErrorMessage: React.FC<IPageErrorMessage> = (props) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {}, [location.state]);

  return (
    <section className="w-full h-full pt-10">
      <div className="w-auto h-auto bg-green rounded-xl p-5 relative shadow-md flex flex-col">
        <h1 className="pb-1 pr-1 pl-1 text-bg text-3xl font-semibold">
          {t("components.error")}:
        </h1>
      </div>
      <p className="text-2xl font-semibold py-10">
        {location?.state?.error || ""}
      </p>
      <div className="flex flex-row gap-[10px] align-baseline">
        <button
          onClick={() => navigate(-2)}
          className=" bg-green text-white font-semibold py-2 px-4 rounded"
        >
          {t("mainButtons.back")}
        </button>
        <button
          onClick={() => navigate("/main")}
          className=" bg-green text-white font-semibold py-2 px-4 rounded"
        >
          {t("components.go_to_main")}
        </button>
      </div>
    </section>
  );
};

export default PageErrorMessage;
