import { format } from "date-fns";
import { ru, kk } from "date-fns/locale";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./Calendar.module.css";
import styles from "./Calendar.module.css";
import { useTranslation } from "react-i18next";

export interface ICalendarProps<T> {
  startDate?: Date;
  endDate?: Date;
  setStartDate: Dispatch<SetStateAction<Date | undefined>>;
  setEndDate: Dispatch<SetStateAction<Date | undefined>>;
}

export const Calendar: React.FC<ICalendarProps<any>> = ({
  startDate,
  setStartDate,
  setEndDate,
  endDate,
}) => {
  const { t, i18n } = useTranslation();

  const [range, setRange] = useState<DateRange | undefined>(undefined);

  useEffect(() => {
    setRange({ from: startDate, to: endDate });
    console.log(i18n.language?.includes("ru"), i18n.language)
  }, [startDate, endDate]);

  let footer = <p className={styles.label}> </p>;
  if (range?.from) {
    if (!range.to) {
      footer = (
        <p className={styles.label}>{format(range?.from, "dd.MM.yyyy")}</p>
      );
    } else if (range.to) {
      footer = (
        <p className={styles.label}>
          {format(range?.from, "dd.MM.yyyy")}–{format(range?.to, "dd.MM.yyyy")}{" "}
        </p>
      );
    }
  }


  return (
    <div className="my-3">
      <span className={styles.label}>{t("components.choose_date")}:</span>
      {footer}
      <DayPicker
        locale={i18n.language?.includes("ru") ? ru : kk}
        id="test"
        mode="range"
        defaultMonth={new Date()}
        selected={range}
        onSelect={(date) => {
          setStartDate(date?.from);
          setEndDate(date?.to);
          setRange(date);
        }}
        className={styles.rdp}
        disabled={{ after: new Date() }}
      />
    </div>
  );
};

export default Calendar;
