import axios, { AxiosResponse } from "axios";
import { GetResponse, PostResponse } from "./response";
import i18n from "../../i18n";

interface LooseObject {
  [key: string]: any;
}

export const getCardsList = async (
  nowLocation:any,
  currentPage: number = 1,
  itemsPerPage: number = 10,
  productTypeCode: any,
  orderName: any,
  purity: any,
  ntin: any,
  metal: any,
  countryCode: any,
  brand: any,
  article: any,
): Promise<any> => {
  try {
    const params = new URLSearchParams({
      page: currentPage.toString(),
      size: itemsPerPage.toString(),
    }).toString();

    const requestBody: LooseObject = {};

    if (orderName) {
      requestBody.name = orderName;
    }
    if (purity) {
      requestBody.purity = purity;
    }
    if (ntin) {
      requestBody.ntin = ntin;
    }
    if (metal) {
      requestBody.metal = metal;
    }
    if (countryCode) {
      requestBody.countryCode = countryCode;
    }
    if (brand) {
      requestBody.brand = brand;
    }
    if (article) {
      requestBody.article = article;
    }
    const response = await axios.post(
      `${process.env.REACT_APP_URL_NKT_SERVICE}/product-cards/list/${nowLocation}/${productTypeCode}?${params}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

export const getTypesList = async (
  currentPage: number = 1,
  itemsPerPage: number = 10,
  endsubSection: any,
): Promise<any> => {
  try {
    const params = new URLSearchParams({
      page: currentPage.toString(),
      size: itemsPerPage.toString(),
    }).toString();

    const response = await axios.get(
      `${process.env.REACT_APP_URL_NKT_SERVICE}/product-cards/types/${endsubSection}?${params}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};
export const getCheqeuesList = async (
  currentPage: number = 1,
  itemsPerPage: number = 10,
  fiscalDocumentNumber?: any,
  kktRegId?: any,
  operationType?: any,
  status?: any,
  refJewelryGroupId?: any,
  ntin?: any,
  startDate?: string,
  endDate?: string,
): Promise<any> => {
  try {
    const params = new URLSearchParams({
      page: currentPage.toString(),
      size: itemsPerPage.toString(),
    }).toString();
    const requestBody: LooseObject = {};

    if (fiscalDocumentNumber) {
      requestBody.fiscalDocumentNumber = fiscalDocumentNumber;
    }
    if (kktRegId) {
      requestBody.kktRegId = kktRegId;
    }
    if (operationType) {
      requestBody.operationType = operationType;
    }
    if (status) {
      requestBody.status = status;
    }
    if (refJewelryGroupId) {
      requestBody.refJewelryGroupId = refJewelryGroupId;
    }
    if (ntin) {
      requestBody.ntin = ntin;
    }
    if (startDate) {
      requestBody.startDate = startDate;
    }
    if (endDate) {
      requestBody.endDate = endDate;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_URL_CHECK_SERVICE}/check/list?${params}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};
export const ChequesListDownload = async (
  fiscalDocumentNumber?: any,
  kktRegId?: any,
  operationType?: any,
  status?: any,
  refJewelryGroupId?: any,
  ntin?: any,
  startDate?: string,
  endDate?: string,
): Promise<any> => {
  try {
    const requestBody: LooseObject = {};

    if (fiscalDocumentNumber) {
      requestBody.fiscalDocumentNumber = fiscalDocumentNumber;
    }
    if (kktRegId) {
      requestBody.kktRegId = kktRegId;
    }
    if (operationType) {
      requestBody.operationType = operationType;
    }
    if (status) {
      requestBody.status = status;
    }
    if (refJewelryGroupId) {
      requestBody.refJewelryGroupId = refJewelryGroupId;
    }
    if (ntin) {
      requestBody.ntin = ntin;
    }
    if (startDate) {
      requestBody.startDate = startDate;
    }
    if (endDate) {
      requestBody.endDate = endDate;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_URL_CHECK_SERVICE}/check/list/download`,
      requestBody,
      {
        withCredentials: true,
        responseType: "blob",
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

export const getNtinCodesByCode = async (
  productTypeCode: any,
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_NKT_SERVICE}/product-cards/search-ntins/${productTypeCode}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка изделий заказа:", error);
    throw error;
  }
};

export const personalizationAddMarksByFile = async (
  docId: string,
  file: File,
): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/add-marks-by-file/${docId}`,
      { file: file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

export const getPersonalizationProductsDoc = async (
  docId: any,
  page: number = 1,
  size: number = 60000,
  sortDirection: "ASC" | "DESC" = "DESC",
): Promise<any> => {
  const params = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sortDirection,
  }).toString();

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/product/list/${docId}?${params}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          Cookie: "your_cookie_here", // Убедитесь, что куки передаются правильно
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе списка изделий:", error);
    throw error;
  }
};

export const addMarksByOrders = async (
  docId: any,
  productId: number,
  orderIds: number[],
): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/add-marks-by-orders/${docId}/${productId}`,
      {
        orderIds,
      },
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при добавлении марок в изделие документа:", error);
    throw error;
  }
};

export const getProductMarks = async (
  docId: any,
  productId: string,
  page: number = 1,
  size: number = 60000,
  sortDirection: "ASC" | "DESC" = "DESC",
): Promise<any> => {
  const params = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sortDirection,
  }).toString();

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/mark/list/${docId}/${productId}?${params}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          Cookie: "your_cookie_here", // Убедитесь, что куки передаются правильно
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе списка марок изделия:", error);
    throw error;
  }
};

export const getPersonalizationProductsManual = async (
  docId: any,
  page: number = 1,
  size: number = 60000,
  sortDirection: "ASC" | "DESC" = "DESC",
): Promise<any> => {
  const params = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sortDirection,
  }).toString();

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/list/${docId}?${params}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          Cookie: "your_cookie_here", // Убедитесь, что куки передаются правильно
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе списка изделий:", error);
    throw error;
  }
};

export const getPersonalizationProductById = async (
  productId: any, // ID изделия
): Promise<any> => {
  try {
    // Выполняем GET-запрос с передачей заголовков и параметров
    const response = await axios.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/get/${productId}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ", // Устанавливаем язык
        },
        withCredentials: true, // Включаем передачу учетных данных (куки)
      },
    );
    // Возвращаем данные ответа
    return response.data;
  } catch (error) {
    // Логируем и пробрасываем ошибку
    console.error("Ошибка при запросе изделия:", error);
    throw error;
  }
};

export const addProductsToDocument = async (
  docId: any,
  products: any,
): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/add/${docId}`,
      products,
      {
        headers: {
          "Accept-Language": "ru-RU",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при добавлении изделий к документу:", error);
    throw error;
  }
};

export const editDocumentPersonalization = async (
  ProductPersonalizationId: any,
  products: any[],
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/edit/${ProductPersonalizationId}`,
      {
        products: products,
      },
      {
        withCredentials: true,
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during order editing:", error);
    throw error;
  }
};

export const deletePersonalization = async (
  docId: any,
  productIds: number[],
): Promise<any> => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/product/${docId}`,
      {
        data: productIds, // передаем массив напрямую
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении изделий из заказа:", error);
    throw error;
  }
};

export const deletePersonalizationMarks = async (
  docId: any,
  productId: any,
  productIds: any[],
): Promise<any> => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/product/marks/${docId}/${productId}`,
      {
        data: productIds, // передаем массив напрямую
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении изделий из заказа:", error);
    throw error;
  }
};

// export const addProductsPersonalization = async (
//   docId: any,
//   products: any[],
// ): Promise<any> => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/add/${docId}`,
//       {
//         products: products,
//         withCredentials: true,
//       },
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Ошибка при удалении изделий из заказа:", error);
//     throw error;
//   }
// };

// export const addMarksToProduct = async (
//   docId: number,
//   productId: number,
//   markIds?: number[],
//   markCodes?: string[],
// ): Promise<any> => {
//   const requestData = {
//     markIds: markIds || null,
//     markCodes: markCodes || null,
//   };

//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/mark/add/${docId}/${productId}`,
//       requestData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
//         },
//         withCredentials: true,
//       },
//     );

//     return response.data;
//   } catch (error) {
//     console.error("Error adding marks to product:", error);
//     throw error;
//   }
// };

// export const addMarksByFile = async (
//   docId: string,
//   productId: string,
//   file: File,
// ): Promise<any> => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/add-marks-by-file/${docId}/${productId}`,
//       { file: file }, // file должен быть корректно передан в формате multipart/form-data
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
//         },
//         withCredentials: true, // Включение cookies в запрос
//       },
//     );

//     return response.data;
//   } catch (error) {
//     console.error("Error adding marks by file:", error);
//     throw error;
//   }
// };

// export const goToNKT = async (): Promise<any> => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/goto`, // Путь к вашему API
//       {
//         headers: {
//           "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ", // Установка языка
//           Cookie: "your-session-cookie-value", // Замените на значение сессионного cookie
//         },
//         withCredentials: true, // Включение cookies в запрос
//         validateStatus: (status) => status === 303, // Обработка только 303 статуса
//       },
//     );

//     // Обработка перенаправления
//     if (response.status === 303) {
//       // Например, если сервер возвращает JSON, обработка данных
//       return response.data;
//     } else {
//       throw new Error(`Unexpected response status: ${response.status}`);
//     }
//   } catch (error) {
//     console.error("Error navigating to NKT:", error);
//     throw error;
//   }
// };

// export const registerOrganization = async (): Promise<any> => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/register-organization`,
//       {},
//       {
//         headers: {
//           "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ", // Установка языка
//           Cookie: "your-session-cookie-value", // Замените на значение сессионного cookie
//         },
//         withCredentials: true,
//       },
//     );

//     if (response.status === 200) {
//       return response.data; // Обработка успешного ответа
//     } else {
//       throw new Error(`Unexpected response status: ${response.status}`);
//     }
//   } catch (error) {
//     console.error("Error registering organization in NKT:", error);
//     throw error;
//   }
// };

// export const goToEtrack = async (token: string): Promise<any> => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/goto-etrack`,
//       {
//         headers: {
//           "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
//           Authorization: `Bearer ${token}`, // Использование переданного токена
//         },
//         withCredentials: true,
//         validateStatus: (status) => [303, 403].includes(status),
//       },
//     );

//     if (response.status === 303) {
//       return response.data;
//     } else if (response.status === 403) {
//       throw new Error(
//         "Forbidden Request: You do not have permission to access this resource.",
//       );
//     } else {
//       throw new Error(`Unexpected response status: ${response.status}`);
//     }
//   } catch (error) {
//     console.error("Error navigating to Etrack:", error);
//     throw error;
//   }
// };

// export const GetjewelryGroup = async (): Promise<any> => {
//   try {
//     console.log(i18n.language);
//     console.log(i18n.language?.includes("ru"));
//     console.log(localStorage.getItem("i18nextLng"));
//     console.log(localStorage.getItem("i18nextLng") === "ru");
//     const response: AxiosResponse<GetResponse> = await axios.get(
//       `${process.env.REACT_APP_URL_REFERENCE_SERVICE}/reference/app/jewelry-groups/list`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
//         },
//         withCredentials: true,
//       },
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error during authentication:", error);
//     throw error;
//   }
// };

export const addProductsPersonalization = async (
  docId: any,
  products: any[],
): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/add/${docId}`,
      {
        products: products,
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении изделий из заказа:", error);
    throw error;
  }
};

export const addMarksToProduct = async (
  docId: number,
  productId: number,
  markIds?: number[],
  markCodes?: string[],
): Promise<any> => {
  const requestData = {
    markIds: markIds || null,
    markCodes: markCodes || null,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/mark/add/${docId}/${productId}`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error adding marks to product:", error);
    throw error;
  }
};

export const addMarksByFile = async (
  docId: string,
  productId: string,
  file: File,
): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/add-marks-by-file/${docId}/${productId}`,
      { file: file }, // file должен быть корректно передан в формате multipart/form-data
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true, // Включение cookies в запрос
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error adding marks by file:", error);
    throw error;
  }
};

export const goToNKT = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/goto`, // Путь к вашему API
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ", // Установка языка
          Cookie: "your-session-cookie-value", // Замените на значение сессионного cookie
        },
        withCredentials: true, // Включение cookies в запрос
        validateStatus: (status) => status === 303, // Обработка только 303 статуса
      },
    );

    // Обработка перенаправления
    if (response.status === 303) {
      // Например, если сервер возвращает JSON, обработка данных
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error navigating to NKT:", error);
    throw error;
  }
};

export const registerOrganization = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/register-organization`,

      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ", // Установка языка
          Cookie: "your-session-cookie-value", // Замените на значение сессионного cookie
        },
        withCredentials: true,
      },
    );

    if (response.status === 200) {
      return response.data; // Обработка успешного ответа
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error registering organization in NKT:", error);
    throw error;
  }
};

export const goToEtrack = async (token: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/goto-etrack`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          Authorization: `Bearer ${token}`, // Использование переданного токена
        },
        withCredentials: true,
        validateStatus: (status) => [303, 403].includes(status),
      },
    );

    if (response.status === 303) {
      return response.data;
    } else if (response.status === 403) {
      throw new Error(
        "Forbidden Request: You do not have permission to access this resource.",
      );
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error navigating to Etrack:", error);
    throw error;
  }
};

export const GetjewelryGroup = async (): Promise<any> => {
  try {
    console.log(i18n.language);
    console.log(i18n.language?.includes("ru"));
    console.log(localStorage.getItem("i18nextLng"));
    console.log(localStorage.getItem("i18nextLng") === "ru");
    const response: AxiosResponse<GetResponse> = await axios.get(
      `${process.env.REACT_APP_URL_REFERENCE_SERVICE}/reference/app/jewelry-groups/list`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const getNtinCode = async (name: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_RECEIVE_SERVICE}/nkt/search/cards?name=${name}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

export const getChequeByCode = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_CHECK_SERVICE}/check/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching product card:", error);
    throw error;
  }
};

export const downloadCheque = async (id: any): Promise<Blob> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_CHECK_SERVICE}/check/${id}/download`,
      {
        headers: {
          "Accept-Language": "ru-RU",
        },
        withCredentials: true,
        responseType: "blob",
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при просмотре и печати документа:", error);
    throw error;
  }
};

export const generateJwt = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/nkt/gen-jwt`,
      {
        headers: {
          "Accept-Language": "ru-RU", // Указание языка
          Cookie: "your-session-id", // Замените "your-session-id" на фактический ИД сессии
        },
        withCredentials: true, // Это необходимо, если сервер требует авторизации
      },
    );
    return response.data;
  } catch (error: any) {
    console.error("Ошибка при генерации JWT токена:", error);
    throw error;
  }
};
