export const normalizedLocale = (locale: any) => {
    return locale
        ? locale.toLowerCase().startsWith('ru')
            ? 'ru'
            : locale.toLowerCase().startsWith('en')
              ? 'en'
              : locale.toLowerCase().startsWith('kk')
                ? 'kk'
                : locale.toLowerCase()
        : 'en';
};
