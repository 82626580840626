import React, { useState } from "react";
import style from "./CheckBox.module.css";

function CheckBox({ id, checked, onChange }: any) {
  const checkboxClass = checked
    ? `${style.customCheckboxBox} ${style.checked}`
    : style.customCheckboxBox;

  return (
    <label
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      style={{ marginBottom: 0 }}
      className={style.customCheckbox}
    >
      <input
        key={id}
        id={id}
        className={style.checkboxInput}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className={style.customCheckboxLabel}>
        <span className={checkboxClass}></span>
      </span>
    </label>
  );
}

export default CheckBox;
