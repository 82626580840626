import * as XLSX from "xlsx";

export const generateAndDownloadExcel = (data: any[], name?: string) => {
  
  // Создание нового workbook
  const workbook = XLSX.utils.book_new();
  const sheetName = "Sheet1";

  // Преобразование данных в формат, понятный для xlsx
  const wsData = [
    Object.keys(data[0]), // Заголовки столбцов
    ...data.map((obj) => Object.values(obj)), // Данные
  ];

  // Создание нового листа
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  XLSX.utils.book_append_sheet(workbook, ws, sheetName);

  // Сохранение файла
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${name ? name : "data"}.xlsx`;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
