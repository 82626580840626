import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const documentData = {
  refCountryExport: null,
  refEaeuCountry: null,
  manufacturerName: null,
  refCustomsClearanceDocType: null,
  customsClearanceRegNumber: null,
  customsClearanceRegAt: null,
  refCustomsDecisionCode: null,
  customsDecisionAt: null,
  customsDecisionDocNumber: null,
  protocolNumber: null,
  protocolAt: null,
  expertNumber: null,
  expertAt: null,
  controlActNumber: null,
  controlActAt: null,
  note: null,
  documentPaperNumber: null,
  documentPaperAt: null,
  hasPaperInfo: null,
  refWithdrawalReason: null,
  refPutReason: null,
  refOperationType: null,
  foundationDocumentName: null,
  foundationDocumentNumber: null,
  foundationDocumentDate: null,
};

export const documentState = createSlice({
  name: "documentState",
  initialState: {
    isSafe: false,
    documentData: documentData,
  },
  reducers: {
    saveDataDocument: (state, action) => {
      state.isSafe = true;
      state.documentData = {
        ...state.documentData,
        ...action.payload,
      };
    },
    deleteDataDocument: (state) => {
      state.isSafe = false;
      state.documentData = { ...documentData };
    },
  },
});

export const { saveDataDocument, deleteDataDocument } = documentState.actions;

export default documentState.reducer;
