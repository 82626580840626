import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { format, parse } from "date-fns";
import { kk, ru } from "date-fns/locale";
import { useField, useFormikContext } from "formik";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Tooltip } from "react-tooltip";
import "./Calendar.module.css";
import { default as style, default as styles } from "./Calendar.module.css";
import { useTranslation } from "react-i18next";

export interface ICalendarProps {
  name: string;
  disabled?: boolean;
  onChange?: () => void;
  documentStatus?:any;
}

export const CalendarSelectFormik: React.FC<ICalendarProps> = (props) => {
  const { documentStatus } = props;
  const { t, i18n } = useTranslation();
  const { setFieldValue, validateField } = useFormikContext();
  const [field, meta] = useField(props.name);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    field.value ? parse(field.value, "dd.MM.yyyy", new Date()) : undefined,
  );

  const handleArrowClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleDateChange = (date: Date | undefined) => {
    if (date) {
      setSelectedDate(date);
      const formattedDate = format(date, "dd.MM.yyyy");
      setFieldValue(props.name, formattedDate, false); // Сохраняем дату в нужном формате
      validateField(props.name); // Обновляем валидацию только для этого поля
      if (props.onChange) {
        props.onChange();
      }
    }
  };

  const handleClear = () => {
    setSelectedDate(undefined);
    setFieldValue(props.name, "", true); // Триггерим валидацию при очистке
    if (props.onChange) {
      props.onChange();
    }
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    if (field.value) {
      const parsedDate = parse(field.value, "dd.MM.yyyy", new Date());
      if (!isNaN(parsedDate.getTime())) {
        setSelectedDate(parsedDate);
      } else {
        console.error("Invalid parsed date:", field.value);
      }
    }
  }, [field.value]);

  const renderCalendar = () => (
    <DayPicker
      locale={i18n.language?.includes("ru") ? ru : kk}
      id={`calendar-select-${props.name}`}
      selected={selectedDate}
      onSelect={handleDateChange}
      mode="single"
      defaultMonth={new Date()}
      className={styles.rdp}
      disabled={{ after: new Date() }}
    />
  );

  return (
    <div className={styles.inputContainer}>
      <input
        type="text"
        value={selectedDate ? format(selectedDate, "dd.MM.yyyy") : ""}
        placeholder={t("components.choose")}
        readOnly
        disabled={props.disabled}
        className={styles.field}
        data-tooltip-id={`select-date-tooltip-${props.name}`}
        onClick={handleArrowClick}
      />
      {/* {field.value || selectedDate ? (
        <button
          type="button"
          className={styles.clearButton}
          onClick={handleClear}
        >
          &times;
        </button>
      ) : (
        <img
          className={styles.arrow}
          src="/icons/arrowDown.svg"
          onClick={handleArrowClick}
          data-tooltip-id={`select-date-tooltip-${props.name}`}
        />
      )} */}
      
       {(documentStatus && (documentStatus === "DRAFT" || documentStatus === "new")) ? (
        field.value || selectedDate ? (
          <button
            type="button"
            className={styles.clearButton}
            onClick={handleClear}
          >
            &times;
          </button>
        ) : (
          <img
            className={styles.arrow}
            src="/icons/arrowDown.svg"
            onClick={handleArrowClick}
            data-tooltip-id={`select-date-tooltip-${props.name}`}
          />
        )
      ) : null}
      <Tooltip
        id={`select-date-tooltip-${props.name}`}
        className={classNames(style.wrapperTooltip, "drop-shadow-2xl")}
        openOnClick={true}
        clickable={true}
        noArrow={true}
        opacity={1}
        place={"bottom-end"}
        afterShow={() => setIsTooltipOpen(true)}
        afterHide={() => setIsTooltipOpen(false)}
      >
        {renderCalendar()}
      </Tooltip>
    </div>
  );
};

export default CalendarSelectFormik;
