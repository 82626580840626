import Skeleton from "react-loading-skeleton";
import React from "react";
interface MockSkeletonProps {
    index: any;
  }

  const MockSkeletonProducts: React.FC<MockSkeletonProps> = ( {index}:any ) => {
  return (
    <tr>
      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600 ">
        {index + 1}
      </td>
      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600 ">
        <Skeleton width={130} height={20} />
      </td>
      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
        <Skeleton width={130} height={20} />
      </td>
      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600 ">
      <div className="flex justify-center items-center">
    <img
      src="/icons/ellipsis-vertical.svg"
      className="h-6 w-6 duration-200 group-hover:brightness-200 group-hover:invert-1"
    />
  </div> 
      </td>
    </tr>
  );
};

export default MockSkeletonProducts;