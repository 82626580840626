import axios, { AxiosResponse } from "axios";
import { AuthResponse } from "./response";
import i18n from "../../i18n";

// Функция для авторизации с использованием signedXML
export const authenticate = async (signedXML: string): Promise<any> => {
  try {
    const requestBody = {
      signedXML: signedXML,
    };

    const response: AxiosResponse<AuthResponse> = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/authorize`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

// Функция для запроса дока с использованием signedXML
export const DocumentHeadReg = async (signedXML: string): Promise<any> => {
  try {
    const requestBody = {
      signedXML: signedXML,
    };

    const response: AxiosResponse<AuthResponse> = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/registration/generate-docs`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

// Функция для dowload дока
export const viewAndPrintDocumentHead = async (
  filename: any,
): Promise<Blob> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/download/temp/${filename}`,
      {
        headers: {
          "Accept-Language": "ru-RU",
        },
        withCredentials: true,
        responseType: "blob", // важно оставить responseType как 'blob'
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при просмотре и печати документа:", error);
    throw error;
  }
};

export const userInfo = async (accessToken?: string): Promise<any> => {
  try {
    const response: AxiosResponse<AuthResponse> = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/me`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const refreshAccessToken = async (
  refreshToken: string,
): Promise<any> => {
  try {
    const requestBody = {
      refreshToken: refreshToken,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/refresh-token`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during token refresh:", error);
    throw error;
  }
};

export const logoutUser = async (refreshToken: string): Promise<any> => {
  try {
    const requestBody = {
      refreshToken: refreshToken,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/logout`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

export const searchByIin = async (iinBin: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/organisation/search-by-iin-bin/${iinBin}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

export const userFiles = async (): Promise<any> => {
  try {
    const response: AxiosResponse<AuthResponse> = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/files`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const downloadUserFiles = async (filename: any) => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/download/${filename}`,
      {
        headers: {
          "Accept-Language": "ru-RU",
        },
        withCredentials: true,
        responseType: "blob", // важно оставить responseType как 'blob'
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при просмотре и печати документа:", error);
    throw error;
  }
};

export const getRoleMatrix=async()=>{
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/users/search-modules-permissions`,
      {
        headers: {
          "Accept-Language": "ru-RU",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении матрицы ролей",error);
    throw error
  }
}
