import React from "react";
import styles from "./ButtonComponent.module.css";

// Определение интерфейса для пропсов
interface ButtonOrLinkProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "green" | "red" | "blue"| "grey";
}

const ModalButton: React.FC<ButtonOrLinkProps> = ({
  color = "red",
  ...props
}) => {
  return (
    <button className={`${styles.button} ${styles[`${color}`]}`} {...props} />
  );
};

export default ModalButton;
