import React from 'react';
import styles from './loader.module.css'; // Импорт CSS модулей

const Loader: React.FC = () => {
    return (
        <div className={styles.loaderBackdrop}>
            <div className={styles.loaderCircle}></div>
        </div>
    );
};

export default Loader;
